// useMutationObserver.js
import { useEffect, useRef, useState } from "react";
import {
  addImageInDropzone,
  findAndReplaceObjectByKey,
  findAndReplacePopulate,
  findDropZone,
} from "../Views/DCMViewer/utils/basic_utils";
import { sendFileUpload } from "../Views/DCMViewer/actions/dcm_actions";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

let preCont;
let changeFlag = false;
const useMutationObserver = (
  formData,
  setupdatedSchema,
  formDataReplica,
  UpdateForm
) => {
  const [progress, setProgress] = useState({});
  const [hasExecuted, setHasExecuted] = useState(false);
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  let { search } = useLocation();
  const query = new URLSearchParams(search);

  const updateState = (dataQuestion, imgSrc) => {
    setupdatedSchema((prev) => ({
      components: findAndReplaceObjectByKey(
        prev?.components
          ? prev?.components
          : formData?.form_schema?.components ||
              formData?.adjudication_form_schema?.components,
        dataQuestion,
        imgSrc,
        "update"
      ),
    }));
  };

  const addImageFiles = async (imageFiles, dataQuestion, fileName = "") => {
    const dropzoneContainers = document.querySelectorAll(
      ".dropzone.dropzone-default.dz-clickable"
    );

    let prevDropzoneContainers;
    if (dropzoneContainers.length > 0) {
      prevDropzoneContainers = dropzoneContainers;
      preCont = dropzoneContainers;
    } else {
      prevDropzoneContainers = preCont;
    }

    prevDropzoneContainers?.forEach((container) => {
      addImageInDropzone(
        container,
        dataQuestion,
        fileName,
        imageFiles,
        setupdatedSchema,
        formData,
        changeFlag,
        dispatch
      );
    });
  };

  useEffect(() => {
    if (query.get("role") === "quality_control") {
      return;
    }
    const logMutations = (mutationsList, observer) => {
      mutationsList.forEach((mutation) => {
        findDropZone(
          formData,
          changeFlag,
          fileInputRef,
          setProgress,
          progress,
          addImageFiles,
          sendFileUpload,
          query.get("stack_id"),
          updateState,
          setupdatedSchema,
          dispatch
        );
      });
    };

    const observer = new MutationObserver(logMutations);
    const targetElement = document.getElementById("form-side-bar");

    if (!targetElement) {
      return;
    }

    const config = {
      childList: true,
      attributes: true,
      subtree: true,
    };

    observer.observe(targetElement, config);

    return () => observer.disconnect();
  }, [
    formData,
    changeFlag,
    fileInputRef,
    setProgress,
    progress,
    addImageFiles,
    sendFileUpload,
    updateState,
    setupdatedSchema,
    dispatch,
  ]);

  useEffect(() => {
    if (hasExecuted || query.get("role") === "quality_control") {
      return;
    }

    formDataReplica = { ...formData };

    if (UpdateForm && !formData?.adjudication_form_schema) {
      UpdateForm = false;

      let screenshot_urls = {};
      if (formData?.adjudication_screenshot_urls) {
        if (localStorage.getItem(query.get("stack_id"))) {
          screenshot_urls =
            formData?.grading_screenshot_urls[
              localStorage.getItem(query.get("stack_id"))
            ];
          console.log("removinggg");
          // localStorage.removeItem(query.get("stack_id"));
        } else {
          screenshot_urls = formData?.adjudication_screenshot_urls;
        }
      } else {
        screenshot_urls = formData?.screenshot_urls;
      }
      console.log("screenshot_urls", screenshot_urls);
      if (screenshot_urls) {
        Object.keys(screenshot_urls)?.forEach((key) => {
          if (key !== "lesions_area") {
            setupdatedSchema((prev) => ({
              components: findAndReplacePopulate(
                prev?.components
                  ? prev?.components
                  : formData?.form_schema?.components ||
                      formData?.adjudication_form_schema?.components,
                key,
                {
                  urls: [...screenshot_urls[key]],
                },
                setupdatedSchema,
                formData,
                changeFlag,
                dispatch
              ),
            }));
          }
        });
        setHasExecuted(true);
      }
    }
  }, [formData]);
};

export default useMutationObserver;
