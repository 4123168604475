import React, { useState, useRef, useEffect } from "react";

import cornerstone from "cornerstone-core";
import cornerstoneTools from "cornerstone-tools";

import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router";
import { toPng } from "html-to-image";
import {
  drawRectangleStatic,
  addStaticLineWithText,
  panImageEvent,
  ZoomMouseWheel,
  addAnnotations,
} from "./utils";
import {
  get_slope_from_line,
  intercept_from_line,
  is_inside_polygon,
} from "../utils/basic_utils";
import { drawBrushPixels } from "../tools/util/drawBrush";

import {
  createContours,
  simplifyContour,
  get_thickness_map,
} from "./processing/thickness";
import {
  extractPointsCoordinates,
  get_annotations,
  opMetadataFetch,
  opPoints,
  save_annotations,
  sendFileUpload,
  veriticalMaxDistance,
} from "../actions/dcm_actions";
import MultiPointProbeTool from "../tools/annotation/MultiPointProbeTool";
import MultiLineTool from "../tools/annotation/MultiLineTool";
import { responseLison } from "./testMl";
import LisonTool from "../tools/annotation/LisonTool";

const getModule = cornerstoneTools.getModule;
const { getters, setters } = getModule("segmentation");

function get_line_pixels(A, B) {
  var m = slope(A, B);
  var b = intercept(A, m);

  var coordinates = [];
  for (var x = A[0]; x <= B[0]; x++) {
    var y = m * x + b;
    coordinates.push([parseInt(x), parseInt(y)]);
  }

  return coordinates;
}
function get_circle_pixels_m(centerX, centerY, radius, start, end) {
  var points = [];
  for (var degree = start; degree < end; degree++) {
    var radians = (degree * Math.PI) / 180;
    var x = centerX + radius * Math.cos(radians);
    var y = centerY + radius * Math.sin(radians);

    points.push([parseInt(x), parseInt(y)]);
  }

  return points;
}

function slope(a, b) {
  if (a[0] == b[0]) {
    return null;
  }

  return (b[1] - a[1]) / (b[0] - a[0]);
}

function intercept(point, slope) {
  if (slope === null) {
    // vertical line
    return point[0];
  }

  return point[1] - slope * point[0];
}

function roundNumbers(number) {
  // Check if the input is a string and convert it to a number
  if (typeof number === "string") {
    number = parseFloat(number);
  }

  // Check if number is a finite number and has more than 3 digits after the decimal point
  if (Number.isFinite(number) && Math.abs(number) >= 0.001) {
    return parseFloat(number.toFixed(3));
  } else {
    return number;
  }
}

let setPref = false;
export default function OPViewer(props) {
  const dispatch = useDispatch();
  const dicom_data = useSelector((state) => state.dcm);
  const isActive = useSelector((state) => state.dcm).etdrsActive;
  const etdrsFallBack = useSelector((state) => state.dcm).etdrsFallBack;
  let { search } = useLocation();

  const query = new URLSearchParams(search);
  const opData = dicom_data.dicom_data[props.id];

  const dcmRef = useRef(null);
  const [currentNumber, setCurrentNumber] = useState(0);
  const [elmentEnabled, setElementEnabled] = useState(false);

  const prevStartRef = useSelector((state) => state.dcm.prevStartRef);

  const onSaveClick = async () => {
    await save_annotations(
      dicom_data,
      query.get("stack_id"),
      query.get("user_id"),
      query.get("series_no"),
      query.get("role"),
      query.get("form_version_id") ? "additional_form" : "primary_form",
      query.get("form_version_id") ? query.get("form_version_id") : ""
    );

    dispatch({
      type: "Lison_Submit_Status_changed",
      SubmitLisonsStatus: false,
    });
    dispatch({ type: "LoadingOverlay_changed", LoadingOverlay: false });
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  // Define the function you want to call after 500ms of last call
  const handleChange = debounce(() => {
    // Do whatever you want with the input value after 500ms
    onSaveClick();
  }, 500);

  const getToolsState = () => {
    // Get tool state for all enabled tools
    const toolData = cornerstoneTools.getToolState(dcmRef.current, "Etdrs");
    if (!prevStartRef?.x && !prevStartRef?.x) {
      // console.log("Etdrs toolData", toolData);
      // ;
      if (toolData?.data[0] && !setPref) {
        console.log("setting prevStartRef getToolsState ");
        setPref = true;
        // dispatch({
        //   type: "SET_PREV_START_REF",
        //   payload: { ...toolData.data[0].handles.start },
        // });
      }
    }
    const state = {};
    let toools = [...dicom_data.drawingTools];
    toools.push("Etdrs");

    toools.forEach((tool) => {
      const msms = cornerstoneTools.getToolState(dcmRef.current, tool);
      if (tool == "Etdrs") {
        console.log("msms Etdrs", msms);
      }

      if (tool == "Length" && msms) {
        const pixel_spacing = opData.OP.metadata.pixel_spacing;
        msms.pixelSpacing = pixel_spacing;
        msms.data = msms.data.map((obj) => ({
          ...obj,
          pixelSpacing: {
            rowPixelSpacing: pixel_spacing.rowPixelSpacing,
            colPixelSpacing: pixel_spacing.columnPixelSpacing,
          },
        }));
      }
      state[tool] = msms;
    });

    dispatch({
      type: "SET_ANNOTATION_DATA",
      payload: state,
      id: props.id,
      key: "OP",
    });
    if (dicom_data.SubmitLisonsStatus) {
      handleChange();
    }
  };
  const captureScreenshot = async () => {
    console.log("captureScreenshot async");
    // dispatch({ type: "LoadingOverlay_changed", LoadingOverlay: true });
    if (!dicom_data.takeScreenShot) {
      return;
    }
    try {
      console.log("captureScreenshot try");
      dispatch({
        type: "takeScreenShot_changed",
        takeScreenShot: false,
      });
      const dataUrl = await toPng(dcmRef.current);
      const blob = await (await fetch(dataUrl)).blob();
      const randomNumber = Math.floor(1000 + Math.random() * 9000); // Generates a 4-digit random number

      const file = new File(
        [blob],
        `${dicom_data?.basicMetaData?.study_code}_${dicom_data?.basicMetaData?.subject_code}_${dicom_data?.basicMetaData?.visit_label}_${randomNumber}.png`,
        { type: blob.type }
      );
      let responseFileUplaod = await sendFileUpload(
        query.get("stack_id"),
        file
      );

      console.log("changed to new  responseFileUplaod", responseFileUplaod);
      dispatch({
        type: "submitDisabled_changed",
        submitDisable: false,
      });
      dispatch({
        type: "mainScreenShot_url_changed",
        mainScreenShotUrl: responseFileUplaod.urls[0],
      });
    } catch (error) {
    } finally {
      dispatch({ type: "LoadingOverlay_changed", LoadingOverlay: false });

      // setsubmitting(false);
      dispatch({
        type: "Lison_Input_Submit_Status_changed",
        MlLisonsInputsSubmitStatus: false,
      });
    }
    //
  };
  useEffect(() => {
    if (!prevStartRef?.x && !prevStartRef?.y) {
      if (props?.annotations?.Etdrs) {
        console.log(
          "props?.annotations",
          props?.annotations?.Etdrs?.data[0]?.handles?.start
        );
        dispatch({
          type: "SET_PREV_START_REF",
          payload: { ...props?.annotations?.Etdrs?.data[0]?.handles?.start },
        });
      }

      // else {
      //   let rect = opData?.OPT?.metadata;

      //   dispatch({
      //     type: "SET_PREV_START_REF",
      //     payload: {
      //       active: false,
      //       drawnIndependently: true,
      //       hasMoved: true,
      //       moving: false,
      //       x: (rect[2] - rect[0]) / 2 + rect[0],
      //       y: (rect[3] - rect[1]) / 2 + rect[1],
      //     },
      //   });
      // }
    }

    if (props?.annotations?.lisonsAreasData) {
      dispatch({
        type: "Set_Lisons_Area_Data",
        lisonsAreasData: props?.annotations?.lisonsAreasData,
      });
      const areaTypes = props?.annotations?.lisonsAreasData?.area_versions?.map(
        (obj) => obj.area_type
      );

      let options = {
        ...dicom_data.lisonOptions,
      };

      options["lisonAreaOptions"] = areaTypes;
      dispatch({
        type: "Lison_Option_changed",
        lisonOptions: options,
      });
    }
  }, [props.annotations]);

  useEffect(() => {
    if (elmentEnabled) cornerstone.resize(dcmRef.current);
  }, [props.windowWidth, dicom_data.width]);

  function compareMlLisonsInput(data1, data2) {
    if (data1.length !== data2.length) {
      return false; // Different number of elements in the arrays
    }

    for (let i = 0; i < data1.length; i++) {
      const scan1 = data1[i];
      const scan2 = data2[i];

      if (scan1.b_scan_id !== scan2.b_scan_id) {
        return false; // Different b_scan_id
      }

      if (scan1.lines.length !== scan2.lines.length) {
        return false; // Different number of lines in the scans
      }

      for (let j = 0; j < scan1.lines.length; j++) {
        const line1 = scan1.lines[j];
        const line2 = scan2.lines[j];

        if (
          line1.line_id !== line2.line_id ||
          line1.start_x_coor !== line2.start_x_coor ||
          line1.end_x_coor !== line2.end_x_coor
        ) {
          return false; // Different line data
        }
      }
    }

    return true;
  }
  function checkInputData() {
    if (dicom_data.dicom_data) {
      // var annotations = get_annotations(
      //   dicom_data.dicom_data,
      //   dicom_data.drawingTools
      // );
      // //
      // var stack_ids = Object.keys(annotations);
      // let lisonLines = extractPointsCoordinates(annotations[stack_ids[0]].OPT);
      // dispatch({
      //   type: "Lison_Input_Submit_Status_changed",
      //   MlLisonsInputsSubmitStatus: compareMlLisonsInput(
      //     lisonLines,
      //     dicom_data?.MlLisonsInputData
      //   ),
      // });
    }
  }
  useEffect(() => {
    let timeoutId;

    const delayedCheckInputData = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(() => {
        if (!elmentEnabled) {
          return;
        } else {
          if (
            dicom_data?.MlLisonsInputData.length !== 0 &&
            dicom_data.LisonActive
          ) {
            checkInputData();
          }
        }
      }, 300);
    };

    delayedCheckInputData();

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [dicom_data.dicom_data]);

  const onMeasurementAdded = (e) => {
    console.log("AOA onMeasurementAdded");
    // Get tool state for all enabled tools on measurementAdded
    getToolsState();
  };

  const onMeasurementModified = (e) => {
    console.log("AOA onMeasurementModified");
    // Measurement modified
    getToolsState();
  };
  const onWindowResize = () => {
    cornerstone.updateImage(dcmRef.current);
  };

  function findClosest(points, target) {
    let closestDiff = Infinity;
    let closestList = null;

    for (let i = 0; i < points.length; i++) {
      let diff = Math.abs(points[i][1] - target);
      if (diff < closestDiff) {
        closestDiff = diff;
        closestList = i;
      }
    }

    return closestList;
  }

  const onMouseClick = (e) => {
    console.log("mouse click");
    if (isActive) {
      onMouseUp();
      getToolsState();
    } else {
      setCurrentNumber(currentNumber + 1);
      if (
        dicom_data.selectedTool === "MOUSE" ||
        dicom_data.selectedTool === "ThicknessMap"
      ) {
        const resp = findClosest(
          opData.OPT.metadata.frames,
          e.detail.currentPoints.image.y
        );

        if (resp) {
          dispatch({
            type: "DICOM_CHANGE_FRAME",
            changeId: props.id,
            changeType: "OP",
            frame: resp,
          });
        }
      }
    }
  };

  const addRectangleStatic = () => {
    const RectangleStatic_v = cornerstoneTools.RectangleStatic;
    cornerstoneTools.addTool(RectangleStatic_v);
    cornerstoneTools.setToolActive("RectangleStatic", { mouseButtonMask: 1 });
  };

  const addLineStatic = () => {
    const LineStatic_v = cornerstoneTools.LineStatic;
    cornerstoneTools.addTool(LineStatic_v);
    cornerstoneTools.setToolActive("LineStatic", { mouseButtonMask: 1 });
  };

  const addThicknessMapStatic = () => {
    const ThicknessMap_v = cornerstoneTools.ThicknessMap;
    cornerstoneTools.addTool(ThicknessMap_v);
    cornerstoneTools.setToolActive("ThicknessMap", { mouseButtonMask: 1 });

    const Etdrs = cornerstoneTools.Etdrs;
    cornerstoneTools.addTool(Etdrs);
    cornerstoneTools.setToolActive("Etdrs", { mouseButtonMask: 1 });
  };

  // On Frame Change
  useEffect(() => {
    if (elmentEnabled) {
      // if(!dicom_data.syncScans){
      //   if(dicom_data.changeId !== props.id){
      //     return
      //   }
      // }
      if (dicom_data.changeId !== props.id) {
        return;
      }

      cornerstoneTools.clearToolState(dcmRef.current, "LineStatic");
      const verticalDistances = [];
      let pointsArray = opData.OPT.metadata.frames;
      for (let i = 1; i < pointsArray.length; i++) {
        const startYDistance = pointsArray[i][1] - pointsArray[i - 1][1];
        const endYDistance = pointsArray[i][3] - pointsArray[i - 1][3];
        verticalDistances.push([startYDistance, endYDistance]);
      }

      addStaticLineWithText(
        dcmRef,
        opData.OPT.metadata.frames[dicom_data.currentFrame],
        `Frame ${dicom_data.currentFrame + 1}`
      );
      if (opData.OP.vertical_line !== null) {
        addStaticLineWithText(dcmRef, opData.OP.vertical_line, ``);
      }

      cornerstone.updateImage(dcmRef.current);
    }
  }, [dicom_data.currentFrame, opData.OP.vertical_line]);

  const onMouseDrag = (e) => {
    if (dicom_data.selectedKey === "Shift") {
      panImageEvent(e);
      return;
    }

    if (dicom_data.selectedTool !== "DragRL") {
      return;
    }
    // Check if mouse is inside rectangle otherwise ignore
    if (
      !is_inside_polygon(
        [e.detail.currentPoints.image.x, e.detail.currentPoints.image.y],
        opData.OPT.metadata.rect_poly
      )
    ) {
      return;
    }

    // Drag Left to right or right to left
    let xmin = opData.OPT.metadata.rect[0];
    let xmax = opData.OPT.metadata.rect[2];

    let currentFrameData = opData.OPT.metadata.frames[dicom_data.currentFrame];

    let total_width = xmax - xmin;
    let normalizedX = (e.detail.currentPoints.image.x - xmin) / total_width;

    let slope = get_slope_from_line(currentFrameData);
    let intercept = intercept_from_line(currentFrameData);

    let yh = slope * e.detail.currentPoints.image.x + intercept;

    dispatch({
      type: "DICOM_CHANGE_VERTICAL_LINE",
      OP: [
        e.detail.currentPoints.image.x,
        yh - 50,
        e.detail.currentPoints.image.x,
        yh + 50,
      ],
      OPT: [
        normalizedX * opData.OPT.metadata.meta.width,
        0,
        normalizedX * opData.OPT.metadata.meta.width,
        opData.OPT.metadata.meta.height,
      ],
      id: props.id,
    });
  };

  const onKeyboardKeyDown = (e) => {
    if (e.key === "ArrowUp" && dicom_data.currentFrame === 0) {
      dispatch({
        type: "DICOM_CHANGE_FRAME",
        changeId: props.id,
        changeType: "OP",
        frame: dicom_data.currentFrame + 1,
      });
    }
  };
  useEffect(() => {
    if (!elmentEnabled) return;
    const toolData = cornerstoneTools.getToolState(dcmRef.current, "Etdrs");

    if (toolData?.data) {
      toolData.data.forEach((data) => {
        console.log("toogling", isActive);
        data.visible = isActive; // Toggle the visibility
      });

      cornerstone.updateImage(dcmRef.current); // Force re-render
    }
    getToolsState();
  }, [isActive]);
  useEffect(() => {
    document.addEventListener("keydown", onKeyboardKeyDown);
    // document.addEventListener("keyup", onKeyboardKeyUp);
    // return () => {
    //   document.removeEventListener("keydown", onKeyboardKeyDown);
    //   // document.removeEventListener("keyup", onKeyboardKeyUp);
    // };
  }, []);
  const onMouseWheel = (e) => {
    if (dicom_data.selectedKey === "Shift") {
      // If control is pressed with mouse wheel scroll, we perform zoom in and out
      ZoomMouseWheel(e, cornerstone);
      return;
    }
    // If mouse is on image, we perform custom stack scroll
    if (e.detail.spinY < 0) {
      if (dicom_data.currentFrame > 0) {
        setCurrentNumber(currentNumber - 1);
        dispatch({
          type: "DICOM_CHANGE_FRAME",
          changeId: props.id,
          changeType: "OP",
          frame: dicom_data.currentFrame - 1,
        });
      }
    } else {
      if (dicom_data.currentFrame < opData.OPT.metadata.frames.length - 1) {
        setCurrentNumber(currentNumber + 1);
        dispatch({
          type: "DICOM_CHANGE_FRAME",
          changeId: props.id,
          changeType: "OP",
          frame: dicom_data.currentFrame + 1,
        });
      }
    }
  };
  const pushPoints = (currentRef, pointx, pointy) => {
    // pointx = 35;
    // pointy = 135;
    cornerstoneTools.addToolState(currentRef, "LisonProbe", {
      active: false,
      color: "yellow",
      cachedStats: {
        mo: 125,
        sp: 125,
        storedPixels: [125],
        x: pointx,
        y: pointy,
      },
      handles: {
        end: {
          active: false,
          highlight: true,
          moving: false,
          x: pointx,
          y: pointy,
        },
      },
      invalidated: false,
      uuid: "1d17782a-f5c0-40b8-afa4-51830f01bf6f",
      visible: true,
    });
  };

  const addAreas = (pixelatedObject) => {
    console.log("etdrsss calling");
    console.log("ezdaAreas", { pixelatedObject });
    const edtrsToolState = cornerstoneTools.getToolState(
      dcmRef.current,
      "Etdrs"
    );
    if (edtrsToolState?.data) {
      edtrsToolState.data.forEach((data) => {
        console.log("toogling");
        data.visible = isActive; // Toggle the visibility
      });

      cornerstone.updateImage(dcmRef.current); // Force re-render
    }

    if (edtrsToolState && edtrsToolState.data.length > 0) {
      // Assuming we're modifying the first instance of the tool
      console.log("edtrsToolState adding", edtrsToolState.data[0]);
      const toolInstance = edtrsToolState.data[0];
      // Step 2: Update the properties of the tool instance
      toolInstance.areas = {
        lesions_1mm_area_mm2: pixelatedObject?.lesions_1mm_area_mm2,
        lesions_1mm_percentage: pixelatedObject?.lesions_1mm_percentage,
        lesions_3mm_area_mm2: pixelatedObject?.lesions_3mm_area_mm2,
        lesions_3mm_percentage: pixelatedObject?.lesions_3mm_percentage,
        lesions_6mm_area_mm2: pixelatedObject?.lesions_6mm_area_mm2,
        lesions_6mm_percentage: pixelatedObject?.lesions_6mm_percentage,
        ETDRS_area_mm2: pixelatedObject?.ETDRS_area_mm2,
        ETDRS_percentage: pixelatedObject?.ETDRS_percentage,
      };
      cornerstone.updateImage(dcmRef.current);
    } else {
      console.error("Tool state not found for 'Etdrs'.");
    }
  };
  const AddLisonAreas = async (dcmRef, objectType, displayType) => {
    let totalArea, midx, midy;
    let lisonsAreas = [];
    let lisonsAreasObj = [];
    let verticalLinesDraw = [];
    console.log("AddLisonAreas function");
    if (dicom_data?.lisonsAreasData.area_versions) {
      let area_versions = dicom_data?.lisonsAreasData?.area_versions;
      const lisonsColors = [
        "rgba(255, 0, 0, 0.09)",
        "rgba(0, 128, 0, 0.09)",
        "rgba(255, 255, 0, 0.09)",
        "rgba(0, 0, 255, 0.09)",
        "rgba(128, 0, 128, 0.09)",
      ];

      const pixelatedObject = area_versions.find(
        (obj) => obj.area_type === objectType
      );
      console.log("area_versions OPViewer", {
        area_versions,
        pixelatedObject,
      });
      // if (dicom_data.isEtdrsActive) {
      //   addAreas(pixelatedObject);
      // }
      addAreas(pixelatedObject);
      if (displayType === "Edges") {
        lisonsAreasObj = [];
        cornerstoneTools.clearToolState(dcmRef.current, "MultiPointProbe");
        cornerstoneTools.clearToolState(dcmRef.current, "MultiLine");
        cornerstoneTools.addTool(MultiPointProbeTool);
        cornerstoneTools.setToolActive("MultiPointProbe", {
          mouseButtonMask: 1,
        });
        // cornerstoneTools.clearToolState(dcmRef.current, "MultiPointProbe");

        totalArea = roundNumbers(pixelatedObject.total_area_size_mm2);
        for (let i = 0; i < pixelatedObject.lesions.length; i++) {
          let concatenatedArray = [];
          lisonsAreasObj = [
            ...lisonsAreasObj,
            {
              label: `L${i + 1}`,
              label_coordinates: {
                x: midx,
                y: midy - 32,
              },
              area_coordinates: {
                x: 10,
                y:
                  opData.OP.metadata.height -
                  (pixelatedObject.lesions.length - i) * 64,
              },

              color: lisonsColors[i % lisonsColors.length],
              invalidated: true,
              area: roundNumbers(pixelatedObject.lesions[i].area_size_mm2),
            },
          ];
          // Add the tool to the enabledElementBinding
          lisonsAreas += `L${i + 1}:${roundNumbers(
            pixelatedObject.lesions[i].area_size_mm2
          )}; `;

          concatenatedArray = pixelatedObject.lesions[i].vertical_lines.concat(
            pixelatedObject.lesions[i].horizontal_lines
          );

          //
          const transformedArray = concatenatedArray.flatMap((line) =>
            line.map(([x, y]) => ({
              x,
              y,
              highlight: true,
              active: true,
              moving: false,
            }))
          );

          let x1 = pixelatedObject.lesions[i].horizontal_lines[0][0][0];
          let y1 = pixelatedObject.lesions[i].horizontal_lines[0][0][1];

          let x2 = pixelatedObject.lesions[i].horizontal_lines[0][1][0];
          let y2 = pixelatedObject.lesions[i].horizontal_lines[0][1][1];

          midx = Math.floor((x1 + x2) / 2);
          midy = Math.floor((y1 + y2) / 2);

          // Add the tool state to the enabledElementBinding
          cornerstoneTools.addToolState(
            dcmRef.current,
            "MultiPointProbe",
            {
              data: [
                {
                  visible: true,
                  active: true,
                  label: `L${i + 1}`,
                  label_coordinates: {
                    x: midx,
                    y: midy - 32,
                  },
                  area_coordinates: {
                    x: 10,
                    y:
                      opData.OP.metadata.height -
                      (pixelatedObject.lesions.length - i) * 64,
                  },
                  total_area: roundNumbers(pixelatedObject.total_area_size_mm2),
                  color: lisonsColors[i % lisonsColors.length],
                  invalidated: true,
                  area: roundNumbers(pixelatedObject.lesions[i].area_size_mm2),
                  handles: {
                    points: transformedArray,
                  },
                  // areas: {
                  //   lesions_1mm_area_mm2: pixelatedObject?.lesions_1mm_area_mm2,
                  //   lesions_1mm_percentage:
                  //     pixelatedObject?.lesions_1mm_percentage,
                  //   lesions_3mm_area_mm2: pixelatedObject?.lesions_3mm_area_mm2,
                  //   lesions_3mm_percentage:
                  //     pixelatedObject?.lesions_3mm_percentage,
                  //   lesions_6mm_area_mm2: pixelatedObject?.lesions_6mm_area_mm2,
                  //   lesions_6mm_percentage:
                  //     pixelatedObject?.lesions_6mm_percentage,
                  //   ETDRS_area_mm2: pixelatedObject?.ETDRS_area_mm2,
                  //   ETDRS_percentage: pixelatedObject?.ETDRS_percentage,
                  // },
                },
              ],
            },
            "enabledElementBinding"
          );
          cornerstone.updateImage(dcmRef.current);
          // cornerstoneTools.clearToolState(dcmRef.current, "MultiPointProbe");
        }
        cornerstoneTools.setToolPassive("MultiPointProbe");
      }

      if (displayType === "filled") {
        cornerstoneTools.clearToolState(dcmRef.current, "MultiPointProbe");
        cornerstoneTools.clearToolState(dcmRef.current, "MultiLine");
        cornerstoneTools.addTool(MultiLineTool);
        cornerstoneTools.setToolActive("MultiLine", {
          mouseButtonMask: 1,
        });
        // cornerstoneTools.clearToolState(dcmRef.current, "MultiLine");
        // area_versions = responseLison.area_versions;

        totalArea = roundNumbers(pixelatedObject.total_area_size_mm2);

        for (let i = 0; i < pixelatedObject.lesions.length; i++) {
          verticalLinesDraw = [
            ...verticalLinesDraw,
            pixelatedObject.lesions[i].horizontal_lines,
          ];
          let x1 = pixelatedObject.lesions[i].horizontal_lines[0][0][0];
          let y1 = pixelatedObject.lesions[i].horizontal_lines[0][0][1];

          let x2 = pixelatedObject.lesions[i].horizontal_lines[0][1][0];
          let y2 = pixelatedObject.lesions[i].horizontal_lines[0][1][1];
          lisonsAreas += `L${i + 1}:${roundNumbers(
            pixelatedObject.lesions[i].area_size_mm2
          )}; `;

          lisonsAreasObj = [
            ...lisonsAreasObj,
            {
              label: `L${i + 1}`,
              label_coordinates: {
                x: Math.floor((x1 + x2) / 2),
                y: Math.floor((y1 + y2) / 2) - 32,
              },
              area_coordinates: {
                x: 10,
                y:
                  opData.OP.metadata.height -
                  (pixelatedObject.lesions.length - i) * 64,
              },

              color: lisonsColors[i % lisonsColors.length],
              invalidated: true,
              area: roundNumbers(pixelatedObject.lesions[i].area_size_mm2),
            },
          ];
        }
      }
      cornerstoneTools.addToolState(
        dcmRef.current,
        "MultiLine",
        {
          data: [
            {
              visible: true,
              active: true,
              labelsData: lisonsAreasObj,
              total_area: roundNumbers(pixelatedObject.total_area_size_mm2),
              invalidated: true,
              handles: {
                points: verticalLinesDraw,
              },
              // areas: {
              //   lesions_1mm_area_mm2: pixelatedObject?.lesions_1mm_area_mm2,
              //   lesions_1mm_percentage: pixelatedObject?.lesions_1mm_percentage,
              //   lesions_3mm_area_mm2: pixelatedObject?.lesions_3mm_area_mm2,
              //   lesions_3mm_percentage: pixelatedObject?.lesions_3mm_percentage,
              //   lesions_6mm_area_mm2: pixelatedObject?.lesions_6mm_area_mm2,
              //   lesions_6mm_percentage: pixelatedObject?.lesions_6mm_percentage,
              //   ETDRS_area_mm2: pixelatedObject?.ETDRS_area_mm2,
              //   ETDRS_percentage: pixelatedObject?.ETDRS_percentage,
              // },
            },
          ],
        },
        "enabledElementBinding"
      );
      cornerstone.updateImage(dcmRef.current);

      cornerstoneTools.setToolPassive("MultiLine");
      setTimeout(() => {
        captureScreenshot();
      }, 5000);
    }

    if (dicom_data.selectedTool === "Lison") {
      cornerstoneTools.addTool(LisonTool);
      cornerstoneTools.setToolActive("Lison", { mouseButtonMask: 1 });
    }
    dispatch({
      type: "selected_area_Changed",
      SelectedArea: {
        TotalArea: totalArea,
        LisonsArea: lisonsAreas,
      },
    });
    getToolsState();
  };
  useEffect(() => {
    if (!elmentEnabled) {
      return;
    }

    if (!dicom_data.LisonActive && dicom_data?.lisonsAreasData.area_versions) {
      cornerstoneTools.clearToolState(dcmRef.current, "MultiLine");
      cornerstoneTools.clearToolState(dcmRef.current, "MultiPointProbe");
    } else {
      console.log("AddLisonAreas");
      AddLisonAreas(
        dcmRef,
        dicom_data.lisonOptions.lisonAreaselected,
        dicom_data.lisonOptions.lisonDisplaySelected
      );
    }
  }, [dicom_data.lisonOptions, dicom_data.LisonActive]);
  useEffect(() => {
    console.log(
      "dicom_data?.lisonsAreasData OPViewer",
      dicom_data?.lisonsAreasData?.area_versions
    );
  }, [dicom_data?.lisonsAreasData]);
  useEffect(() => {
    if (elmentEnabled) {
      let update_d = opData.OP.annotation_toggle;

      if (update_d === undefined) {
        return;
      }
      const toolState = cornerstoneTools.getToolState(
        dcmRef.current,
        update_d.annot_type
      );

      if (toolState === null) {
        return;
      }

      if (update_d.action_type === "delete_LisonProbes") {
        const toolState = cornerstoneTools.getToolState(
          dcmRef.current,
          "LisonProbe"
        );

        if (toolState?.data) {
          toolState.data = [];
          // cornerstoneTools.clearToolState(dcmRef.current, "LisonProbe")
        }
        // toolState.data.splice(update_d.index, 1);
        getToolsState();
      }

      if (update_d.action_type === "Add_Lison_Length_point") {
        let opWidth =
          dicom_data?.dicom_data[query.get("stack_id")]?.OP.metadata?.width;

        // let scalingFactor =

        const toolState = cornerstoneTools.getToolState(
          dcmRef.current,
          "LisonProbe"
        );

        if (toolState?.data) {
          toolState.data = [];
          // cornerstoneTools.clearToolState(dcmRef.current, "LisonProbe")
        }
        // let previous_probs = [...toolState.data];
        const LisonProbeTool = cornerstoneTools.LisonProbeTool;
        //
        cornerstoneTools.addTool(LisonProbeTool);
        // cornerstoneTools.setToolActive("LisonProbe", { mouseButtonMask: 1 });

        // cornerstoneTools.clearToolState(dcmRef.current, "LisonProbe");

        ///////
        // toolState.data = [];
        var annotations = get_annotations(
          dicom_data.dicom_data,
          dicom_data.drawingTools
        );
        //
        var stack_ids = Object.keys(annotations);
        let lisonLines = extractPointsCoordinates(
          annotations[stack_ids[0]].OPT
        );

        for (let i = 0; i < lisonLines.length; i++) {
          for (let j = 0; j < lisonLines[i].lines.length; j++) {
            let frame_coords =
              opData.OPT.metadata.frames[lisonLines[i].b_scan_id];

            let optWidth =
              dicom_data?.dicom_data[query.get("stack_id")]?.OPT?.metadata?.meta
                ?.width;

            let Framecoords =
              opData?.OPT?.metadata?.frames[lisonLines[i]?.b_scan_id];

            const dx = Framecoords[2] - Framecoords[0];
            const dy = Framecoords[3] - Framecoords[1];
            let redLineLength = Math.sqrt(dx * dx + dy * dy);

            let scalingFactor = redLineLength / optWidth;

            //slope= y2-y1/x2-x1
            // calculate the coordinates of the point on the oblique line placed in cartesian grid by knowing the distance from starting poing

            // // step : 1 calculating slope of line
            // const slope =
            //   (frame_coords[3] - frame_coords[1]) /
            //   (frame_coords[2] - frame_coords[0]);

            // // step : 2 Direction vector (x2-x1,y2-y1)
            // let directionVector = [
            //   frame_coords[2] - frame_coords[0],
            //   frame_coords[3] - frame_coords[1],
            // ];

            // step :3 Length of direction vector sqrt((x2-x1)^2,(y2-y1)^2) which is same as redLineLength we already calculated above

            // step : 4 Unit vector (x2-x1/length,y2-y1/length)
            let unitVector = [
              (frame_coords[2] - frame_coords[0]) / redLineLength,
              (frame_coords[3] - frame_coords[1]) / redLineLength,
            ];

            let newStartPoint = [
              frame_coords[0] +
                lisonLines[i].lines[j].start_x_coor *
                  scalingFactor *
                  unitVector[0],

              frame_coords[1] +
                lisonLines[i].lines[j].start_x_coor *
                  scalingFactor *
                  unitVector[1],
            ];

            pushPoints(
              dcmRef.current,
              parseInt(newStartPoint[0]),
              parseInt(newStartPoint[1])
            );

            let newEndPoint = [
              frame_coords[0] +
                lisonLines[i].lines[j].end_x_coor *
                  scalingFactor *
                  unitVector[0],

              frame_coords[1] +
                lisonLines[i].lines[j].end_x_coor *
                  scalingFactor *
                  unitVector[1],
            ];
            pushPoints(
              dcmRef.current,
              parseInt(newEndPoint[0]),
              parseInt(newEndPoint[1])
            );
          }
        }
        ////////
        // for (let i = 0; i < previous_probs; i++) {
        //
        //   toolState.data.push(previous_probs[i]);
        // }
      }
      if (update_d.action_type === "delete") {
        const updatedData = {
          ...dicom_data?.lisonsAreasData,
          area_versions: dicom_data?.lisonsAreasData.area_versions.map(
            (area) => ({
              ...area,
              lesions: area.lesions.filter(
                (_, index) => index !== update_d.index
              ),
            })
          ),
        };
        dispatch({
          type: "Set_Lisons_Area_Data",
          lisonsAreasData: updatedData,
        });

        toolState.data.splice(update_d.index, 1);
        getToolsState();
      }
      cornerstone.updateImage(dcmRef.current);
    }
  }, [opData.OP.annotation_toggle]);

  useEffect(() => {
    console.log("etdrsFallBack etdrsFallBack aa", {
      etdrsFallBack,
      prevStartRef,
    });
    if (!elmentEnabled) return;
    if (!etdrsFallBack) {
      // dispatch({
      //   type: "SET_PREV_START_REF",
      //   payload: {},
      // });
      // const etdrsState = cornerstoneTools.getToolState(dcmRef.current, "Etdrs");
      // const currentStart = etdrsState.data[0].handles.start;
      // dispatch({
      //   type: "SET_PREV_START_REF",
      //   payload: { ...currentStart },
      // });
    }
    if (etdrsFallBack) {
      dispatch({
        type: "etdrsFallBack_changed",
        etdrsFallBack: false,
      });
      const toolData = cornerstoneTools.getToolState(dcmRef.current, "Etdrs");
      let rect = opData.OPT.metadata.rect;
      if (toolData?.data) {
        toolData.data.forEach((data) => {
          data.handles.start = {
            ...data.handles.start,
            x: prevStartRef?.x ?? (rect[2] - rect[0]) / 2 + rect[0],
            y: prevStartRef?.y ?? (rect[3] - rect[1]) / 2 + rect[1],
          };
        });

        cornerstone.updateImage(dcmRef.current);
      }
    }
  }, [etdrsFallBack]);
  const add_Etdrs_circles = () => {
    let element = dcmRef.current;
    var rect = opData.OPT.metadata.rect;

    let area_versions = dicom_data?.lisonsAreasData?.area_versions;

    const pixelatedObject = area_versions.find(
      (obj) => obj.area_type === dicom_data?.lisonOptions.lisonAreaselected
    );

    var scale_x = opData.OP.metadata.pixel_spacing.columnPixelSpacing;
    var circles_radii = [1 / scale_x / 2, 3 / scale_x / 2, 6 / scale_x / 2];
    // prevStartRef.current = {
    //   x: (rect[2] - rect[0]) / 2 + rect[0],
    //   y: (rect[3] - rect[1]) / 2 + rect[1],
    // };
    console.log("etdrsss calling");

    cornerstoneTools.clearToolState(element, "Etdrs");
    cornerstoneTools.addToolState(element, "Etdrs", {
      visible: isActive,
      rect: rect,
      circles_radii: circles_radii,
      areas: {
        lesions_1mm_area_mm2: pixelatedObject?.lesions_1mm_area_mm2,
        lesions_1mm_percentage: pixelatedObject?.lesions_1mm_percentage,
        lesions_3mm_area_mm2: pixelatedObject?.lesions_3mm_area_mm2,
        lesions_3mm_percentage: pixelatedObject?.lesions_3mm_percentage,
        lesions_6mm_area_mm2: pixelatedObject?.lesions_6mm_area_mm2,
        lesions_6mm_percentage: pixelatedObject?.lesions_6mm_percentage,
        ETDRS_area_mm2: pixelatedObject?.ETDRS_area_mm2,
        ETDRS_percentage: pixelatedObject?.ETDRS_percentage,
      },
      handles: {
        start: {
          x: (rect[2] - rect[0]) / 2 + rect[0],
          y: (rect[3] - rect[1]) / 2 + rect[1],
        },
        end: {
          x: rect[2],
          y: rect[3],
        },
      },
    });

    // cornerstoneTools.setToolPassive("Etdrs", { mouseButtonMask: 1 });
  };
  const add_thickness_map_grid = (image) => {
    let element = dcmRef.current;
    var rect = opData.OPT.metadata.rect;

    var scale_x = opData.OP.metadata.pixel_spacing.columnPixelSpacing;
    var circles_radii = [1 / scale_x / 2, 3 / scale_x / 2, 6 / scale_x / 2];

    cornerstoneTools.clearToolState(element, "ThicknessMap");
    cornerstoneTools.addToolState(element, "ThicknessMap", {
      rect: rect,
      circles_radii: circles_radii,
      thickness_image: image,
      handles: {
        start: {
          x: (rect[2] - rect[0]) / 2 + rect[0],
          y: (rect[3] - rect[1]) / 2 + rect[1],
        },
        end: {
          x: rect[2],
          y: rect[3],
        },
      },
    });

    cornerstoneTools.setToolPassive("ThicknessMap", { mouseButtonMask: 1 });
  };

  const metaDataProvider = (type, imageId) => {
    // Custom meta data provider for the viewport(OCT)
    if (
      type === "imagePlaneModule" &&
      dicom_data.selectedTool === "ThicknessMap"
    ) {
      if (imageId.includes(opData.OP.metadata.url)) {
        return {
          rowPixelSpacing: opData.OP.metadata.pixel_spacing.rowPixelSpacing,
          columnPixelSpacing:
            opData.OP.metadata.pixel_spacing.columnPixelSpacing,
        };
      }
    } else if (type === "imagePlaneModule") {
      if (imageId.includes(opData.OP.metadata.url)) {
        return {
          rowPixelSpacing: opData.OP.metadata.pixel_spacing.rowPixelSpacing,
          columnPixelSpacing:
            opData.OP.metadata.pixel_spacing.columnPixelSpacing,
        };
      }
    }
  };

  useEffect(() => {
    cornerstone.metaData.addProvider(metaDataProvider);
  }, [Object.keys(dicom_data.dicom_data).length, dicom_data.selectedTool]);

  useEffect(() => {
    if (!elmentEnabled) {
      return;
    }
    if (
      dicom_data.selectedTool === "ThicknessMap" ||
      dicom_data.selectedTool === "Etdrs"
    ) {
      // On U key press, we calculate thickness map for 2 selected layers and overlay on Fundus image

      var layers = cornerstone.getLayers(dcmRef.current);
      if (layers.length > 1) {
        cornerstone.removeLayer(dcmRef.current, layers[1].layerId);
      }
      cornerstone.updateImage(dcmRef.current);
      if (dicom_data.selectedTool === "Etdrs") {
        const toolData = cornerstoneTools.getToolState(dcmRef.current, "Etdrs");
        if (toolData === undefined && dicom_data.isEtdrsActive) {
          add_Etdrs_circles();
        }
        return;
      }
      var first_point = opData.OPT.metadata.frames[0];
      var last_point =
        opData.OPT.metadata.frames[opData.OPT.metadata.frames.length - 1];

      // Calculate width and height of rectangle
      var width = Math.abs(first_point[0] - last_point[2]);
      var height = Math.abs(first_point[1] - last_point[1]);

      var imgs = get_thickness_map(dcmRef.current, opData.OPT.stack.imageIds, {
        width: opData.OPT.metadata.meta.width,
        height: opData.OPT.metadata.meta.width,
        rowPixelSpacing: opData.OPT.metadata.pixel_spacing.rowPixelSpacing,
        columnPixelSpacing:
          opData.OPT.metadata.pixel_spacing.columnPixelSpacing,
        lut: dicom_data.thicknessLUT,
        angle: opData.OPT.metadata.rect_angle,
        rect: opData.OPT.metadata.rect,
        rect_wh: [width, height],
        op_meta: opData.OP.metadata,
        frames: [
          opData.OPT.metadata.frames[0],
          opData.OPT.metadata.frames[opData.OPT.metadata.frames.length - 1],
        ],
        total_frames: opData.OPT.metadata.frames.length,
      });

      if (imgs === null) {
        return;
      }
      var img = imgs.cnsImage;
      img.rowPixelSpacing = opData.OP.metadata.pixel_spacing.rowPixelSpacing;
      img.columnPixelSpacing =
        opData.OP.metadata.pixel_spacing.columnPixelSpacing;

      add_thickness_map_grid(imgs.thickness_image_array);

      cornerstone.addLayer(dcmRef.current, img, {
        name: "Thickness",
        opacity: 0.7,
        visible: true,
      });
      cornerstone.updateImage(dcmRef.current);
    } else {
      // Remove thickness map layer if any
      var layers = cornerstone.getLayers(dcmRef.current);
      if (layers.length > 1) {
        cornerstoneTools.clearToolState(dcmRef.current, "ThicknessMap");
        cornerstone.removeLayer(dcmRef.current, layers[1].layerId);
        cornerstone.updateImage(dcmRef.current);
      }
    }
  }, [dicom_data.selectedTool]);

  const layers = [
    {
      imageId: opData.OP.stack.imageIds[0],
      options: {
        name: "OP",
        opacity: 0.7,
      },
    },
  ];

  const loadImages = () => {
    const promises = [];

    layers.forEach(function (layer) {
      const loadPromise = cornerstone.loadAndCacheImage(layer.imageId);
      promises.push(loadPromise);
    });

    return Promise.all(promises);
  };

  const addFilteredAnnotations = () => {
    const keysToRemove = ["LisonProbe"];
    let filteredObj = { ...props.annotations };

    console.log("filtered objectss", filteredObj);
    if (Object.keys(filteredObj).length > 0) {
      if (!dicom_data.LisonActive) {
        filteredObj = Object.keys(props.annotations)
          .filter((key) => !keysToRemove.includes(key))
          .reduce((acc, key) => {
            acc[key] = props.annotations[key];
            return acc;
          }, {});
      }
      if (filteredObj.lisonsAreasData) delete filteredObj.lisonsAreasData;
      addAnnotations(filteredObj, dcmRef, isActive);
      // Object.entries(filteredObj).forEach(([annotation_type, annotations]) => {
      //   if (annotation_type === "Etdrs") {
      //     console.log("making etdrs active");
      //     // Add this code to click the ETDRS button
      //     const etdrsButton = document.getElementById("etdrs-button");
      //     if (etdrsButton) {
      //       etdrsButton.click();
      //     }

      //     setTimeout(() => {
      //       const etdrsCircles = document.getElementById("etdrs-circles");
      //       if (etdrsCircles) {
      //         etdrsCircles.click();
      //       }
      //     }, 3000); // 1000ms = 1 second
      //   }
      // });
    }
  };
  useEffect(() => {
    if (!elmentEnabled) {
      return;
    }
    addFilteredAnnotations();
  }, [dicom_data.LisonActive]);
  useEffect(() => {
    let element = dcmRef.current;
    //cornerstone.loadImage(opData.OP.stack.imageIds[0]).then(function (image) {
    //  cornerstone.enable(element);
    //    cornerstone.displayImage(element, image)
    loadImages()
      .then(function (images) {
        cornerstone.enable(element);
        console.log("images", images);
        images.forEach(function (image, index) {
          const layer = layers[index];
          cornerstone.addLayer(element, image, layer.options);

          cornerstone.updateImage(element);
        });
      })
      .then(function () {
        //cornerstoneTools.addStackStateManager(element, ['stack']);
        //cornerstoneTools.addToolState(element, 'stack', opData.OP.stack);

        addRectangleStatic();
        addLineStatic();
        addThicknessMapStatic();

        element.addEventListener("cornerstoneelementresized", onWindowResize);

        drawRectangleStatic(
          dcmRef,
          opData.OPT.metadata.rect,
          -opData.OPT.metadata.rect_angle
        );
        cornerstone.drawImage(dcmRef.current);

        // Get viewport
        addFilteredAnnotations();

        // add_thickness_map_grid(null)

        setElementEnabled(true);
      });
  }, [Object.keys(dicom_data.dicom_data).length]);

  const onMouseDown = (e) => {
    // Record initial position of static rectangle is shift is pressed
    // cornerstoneTools.clearToolState(dcmRef.current, "Lison");
    cornerstoneTools.setToolPassive("Lison");
  };

  function onMouseOver(evt) {
    // Your event handling code here
  }

  const onMouseUp = (e) => {
    if (!isActive) {
      return;
    }

    // Simulate getting the `etdrsState` data from your cornerstoneTools
    const etdrsState = cornerstoneTools.getToolState(dcmRef.current, "Etdrs");
    const currentStart = etdrsState.data[0].handles.start;

    console.log("Current Start Object:", { currentStart, etdrsState });
    // debugger;
    // Compare the current start object with the previous one
    var annotations = get_annotations(
      dicom_data.dicom_data,
      dicom_data.drawingTools
    );
    var stack_ids = Object.keys(annotations);

    let lisonLines = extractPointsCoordinates(annotations[stack_ids[0]].OPT);
    console.log("Start object has changed, clicking the button...", {
      length: lisonLines.length,
      active: dicom_data.LisonActive,
    });
    // if (dicom_data?.lisonsAreasData?.finish_time && !dicom_data.LisonActive) {
    //   console.log("clicking", dicom_data.LisonActive);

    //   const switchElement = document.getElementById("lesion-toggle");
    //   console.log("switchElement", switchElement);
    //   // Check if the switch is off (unchecked) and click it
    //   // if (!switchElement.checked) {
    //   //   switchElement.click();
    //   // }
    // }
    if (
      (prevStartRef?.x !== currentStart?.x ||
        prevStartRef?.y !== currentStart?.y ||
        !prevStartRef) &&
      etdrsState?.data[0]?.visible
    ) {
      console.log("setting prevStartRef");

      let lisonLines = extractPointsCoordinates(
        annotations[query.get("stack_id")].OPT
      );

      console.log("lisonLines", lisonLines);
      if (lisonLines.length > 0) {
        const button = document.getElementById("EzdaProcess");
        console.log("EzdaProcess ", button);
        if (button) {
          button.click();
        }
        console.log("Current Start Object: EzdaProcess");
      }
      // prevStartRef.current = { ...currentStart };

      //
    }

    // Update the ref with the new start object
  };

  useEffect(() => {
    let element = dcmRef.current;
    element.addEventListener("cornerstonetoolsmousedown", onMouseDown);
    element.addEventListener("cornerstonetoolsmousewheel", onMouseWheel);
    element.addEventListener("cornerstonetoolsmousedrag", onMouseDrag);
    element.addEventListener("cornerstonetoolsmouseclick", onMouseClick);
    element.addEventListener("cornerstonetoolsmouseup", onMouseUp);
    element.addEventListener(
      "cornerstonetoolsmeasurementadded",
      onMeasurementAdded
    );
    element.addEventListener(
      "cornerstonetoolsmeasurementmodified",
      onMeasurementModified
    );

    return () => {
      element.removeEventListener("cornerstonetoolsmousedown", onMouseDown);
      element.removeEventListener("cornerstonetoolsmousewheel", onMouseWheel);
      element.removeEventListener("cornerstonetoolsmousedrag", onMouseDrag);
      element.removeEventListener("cornerstonetoolsmouseclick", onMouseClick);
      element.removeEventListener(
        "cornerstonetoolsmeasurementadded",
        onMeasurementAdded
      );
      element.removeEventListener(
        "cornerstonetoolsmeasurementmodified",
        onMeasurementModified
      );
    };
  }, [
    dicom_data.currentFrame,
    dicom_data.selectedTool,
    dicom_data.selectedKey,
    dicom_data.processing,
  ]);

  useEffect(() => {
    if (dcmRef) {
      dispatch({
        type: "Op_Ref_Changed",
        OpRef: dcmRef,
      });
    }
  }, [dcmRef]);
  return (
    <>
      <div
        style={{
          height: `calc(100% - 45px)`,
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <div
          style={{
            height: "100%",
            width: props.width,
            backgroundColor: "black",
            marginBottom: "45px",
          }}
          ref={dcmRef}
        ></div>
      </div>
    </>
  );
}
