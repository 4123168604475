import React from "react";
import { Modal, Box, Typography, Button, Backdrop } from "@mui/material";
import { useDispatch } from "react-redux";

const EtdrsConfirmationModal = ({ open, handleClose, handleProcessSubmit }) => {
  const dispatch = useDispatch();

  // Styles for the modal content box
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "8px",
    textAlign: "center",
  };

  // Dispatch action if "Yes" is clicked
  const handleYes = () => {
    handleClose();
    handleProcessSubmit();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        sx: { bgcolor: "rgba(0, 0, 0, 0.7)" }, // Dim dark background
      }}
    >
      <Box sx={style}>
        <Typography variant="h6" gutterBottom sx={{ textAlign: "left" }}>
          Is the ETDRS Grid properly placed over the anatomical Fovea?
          <br />
          <br />
          If <b>YES</b>, the EZDA and ETDRS information will be processed.
          <br />
          If <b>NO</b>, please center the ETDRS Grid properly and process it
          again.
        </Typography>
        <Box display="flex" justifyContent="space-around" mt={2}>
          <Button variant="contained" color="primary" onClick={handleYes}>
            Yes
          </Button>
          <Button variant="outlined" color="secondary" onClick={handleClose}>
            No
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default EtdrsConfirmationModal;
