import cornerstoneTools from "cornerstone-tools";
import path from "./annotation/drawing/path";

const BaseAnnotationTool = cornerstoneTools.importInternal(
  "base/BaseAnnotationTool"
);

const external = cornerstoneTools.external;

const draw = cornerstoneTools.importInternal("drawing/draw");
//const drawRect = cornerstoneTools.importInternal('drawing/drawRect');
const setShadow = cornerstoneTools.importInternal("drawing/setShadow");
const getNewContext = cornerstoneTools.importInternal("drawing/getNewContext");
const drawTextBox = cornerstoneTools.importInternal("drawing/drawTextBox");
const drawHandles = cornerstoneTools.importInternal("drawing/drawHandles");
const drawCircle = cornerstoneTools.importInternal("drawing/drawCircle");
const drawLinkedTextBox = cornerstoneTools.importInternal(
  "drawing/drawLinkedTextBox"
);
const drawLine = cornerstoneTools.importInternal("drawing/drawLine");

const getToolState = cornerstoneTools.getToolState;
const toolStyle = cornerstoneTools.toolStyle;
//const textStyle = cornerstoneTools.textStyle;
const toolColors = cornerstoneTools.toolColors;
const getModule = cornerstoneTools.getModule;

//const lineSegDistance = cornerstoneTools.importInternal('util/lineSegDistance');
//const getRGBPixels = cornerstoneTools.importInternal('util/getRGBPixels');
const getPixelSpacing = cornerstoneTools.importInternal("util/getPixelSpacing");
const throttle = cornerstoneTools.importInternal("util/throttle");
const circleRoiCursor = cornerstoneTools.importInternal("cursors/index");
const getROITextBoxCoords = cornerstoneTools.importInternal(
  "util/getROITextBoxCoords"
);
const textStyle = cornerstoneTools.textStyle;
const drawRect = cornerstoneTools.importInternal("drawing/drawRect");

/**
 * @public
 * @class Etdrs
 * @memberof Tools.Annotation
 * @classdesc Tool for drawing circular regions of interest, and measuring
 * the statistics of the enclosed pixels.
 * @extends Tools.Base.BaseAnnotationTool
 */
let count = 0;
export default class Etdrs extends BaseAnnotationTool {
  constructor(props = {}) {
    const defaultProps = {
      name: "Etdrs",
    };

    super(props, defaultProps);

    this.mouseMoveCallback = this.mouseMoveCallback.bind(this);
    this.mouseDownCallback = this.mouseDownCallback.bind(this);
    this.mouseUpCallback = this.mouseUpCallback.bind(this);

    this.mouseEventListeners = [
      {
        eventName: cornerstoneTools.EVENTS.MOUSE_DOWN,
        listener: this.mouseDownCallback,
      },
      {
        eventName: cornerstoneTools.EVENTS.MOUSE_MOVE,
        listener: this.mouseMoveCallback,
      },
      {
        eventName: cornerstoneTools.EVENTS.MOUSE_UP,
        listener: this.mouseUpCallback,
      },
    ];
  }

  createNewMeasurement(eventData) {
    const goodEventData =
      eventData && eventData.currentPoints && eventData.currentPoints.image;

    if (!goodEventData) {
      return;
    }

    return {
      visible: true,
      active: false,
      color: undefined,
      invalidated: true,
      handles: {
        start: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: false,
          active: false,
          drawnIndependently: false,
          moveable: false,
          locked: true,
        },
        end: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: false,
          active: false,
          drawnIndependently: false,
          moveable: false,
          locked: true,
        },
        textBox: {
          active: false,
          hasMoved: false,
          movesIndependently: false,
          drawnIndependently: true,
          allowedOutsideImage: true,
          hasBoundingBox: true,
        },
      },
    };
  }

  updateCachedStats(image, element, data) {
    const seriesModule =
      external.cornerstone.metaData.get("generalSeriesModule", image.imageId) ||
      {};
    const modality = seriesModule.modality;
    const pixelSpacing = getPixelSpacing(image);

    data.invalidated = false;
  }

  renderToolData(evt) {
    console.log("Edtrss called");
    const toolData = getToolState(evt.currentTarget, this.name);

    if (!toolData) {
      return;
    }

    const getDistance = external.cornerstoneMath.point.distance;
    const eventData = evt.detail;
    const { image, element, canvasContext } = eventData;
    const lineWidth = toolStyle.getToolWidth();
    const {
      handleRadius,
      drawHandlesOnHover,
      hideHandlesIfMoving,
      renderDashed,
      centerPointRadius,
    } = this.configuration;
    const newContext = getNewContext(canvasContext.canvas);
    const { rowPixelSpacing, colPixelSpacing } = getPixelSpacing(image);
    const lineDash = getModule("globalConfiguration").configuration.lineDash;

    // Meta
    const seriesModule =
      external.cornerstone.metaData.get("generalSeriesModule", image.imageId) ||
      {};
    const modality = seriesModule.modality;
    const hasPixelSpacing = rowPixelSpacing && colPixelSpacing;

    let colors = ["red", "orange", "green"];

    draw(newContext, (context) => {
      var viewport = external.cornerstone.getViewport(element);
      console.log("toolData.data ETDRS", toolData.data);
      for (let i = 0; i < toolData.data.length; i++) {
        const data = toolData.data[i];

        if (data.visible === false) {
          continue;
        }
        console.log("etdrs dataa etdrs", data);
        // Configure

        setShadow(context, this.configuration);

        console.log("etdrs set shadow dataa etdrs");

        // Add visual feedback for active state - thicker lines when moving
        const lineWidth = data.active ? 3 : 1;
        console.log("data.areas", data.areas);
        if (data?.areas) {
          count++;

          let areas = data?.areas;

          // Set font size and font before measurements
          const fontSize = 12; // adjust this value as needed
          context.font = `${fontSize}px Arial`;

          // Add this helper function inside draw callback
          const measureText = (text) => {
            return context.measureText(text).width;
          };

          // Prepare all text strings first
          const textLines = [
            `⌀1 mm: ${areas.lesions_1mm_area_mm2} mm${String.fromCharCode(
              178
            )} (${areas.lesions_1mm_percentage}%)`,
            `⌀3 mm: ${areas.lesions_3mm_area_mm2} mm${String.fromCharCode(
              178
            )} (${areas.lesions_3mm_percentage}%)`,
            `⌀6 mm: ${areas.lesions_6mm_area_mm2} mm${String.fromCharCode(
              178
            )} (${areas.lesions_6mm_percentage}%)`,
            `ETDRS: ${areas?.ETDRS_area_mm2} mm${String.fromCharCode(178)} (${
              areas?.ETDRS_percentage
            }%)`,
          ];

          // Find the widest text and its pixel width
          const textWidths = textLines.map((text) => measureText(text));
          const maxWidth = Math.max(...textWidths);

          // Calculate text box height
          const lineHeight = 15;

          // const totalHeight = textLines.length * lineHeight;

          // Calculate dynamic padding based on image size
          const basePadding = 0;
          const baseImageSize = 768; // Reference size for scaling
          const scaleFactor = Math.min(image.width / baseImageSize, 2); // Cap the scale factor at 2

          const padding = Math.round(basePadding * scaleFactor);
          const extraRightPadding = Math.round(50 * scaleFactor); // Increased and scaled extra padding

          // Calculate the starting x-coordinate
          // Convert canvas coordinates to image coordinates for proper scaling
          const canvasToImageCoords = external.cornerstone.canvasToPixel(
            eventData.element,
            { x: maxWidth, y: 0 }
          );

          const scaledWidth = canvasToImageCoords.x;

          // Calculate coordinates ensuring text stays within image bounds
          const coords = {
            x: Math.max(
              padding,
              image.width - scaledWidth - padding * 1.3 - extraRightPadding
            ),
            y: image.height - padding,
          };

          // Convert image coordinates to canvas coordinates
          const textCoords = external.cornerstone.pixelToCanvas(
            eventData.element,
            coords
          );

          // Draw each line of text from bottom up
          textLines.forEach((text, index) => {
            drawTextBox(
              context,
              text,
              textCoords.x - 10,
              textCoords.y - (textLines.length - index) * lineHeight - 15,
              "white"
            );
          });
        }
        // Draw the circles using the radius data
        if (data.circles_radii) {
          data.circles_radii.forEach((radii, index) => {
            drawCircle(
              context,
              element,
              data.handles.start,
              radii * viewport.scale,
              {
                color: colors[index],
                lineWidth: lineWidth,
                lineDash: [],
              },
              "pixel"
            );
          });
        }
        console.log("dataaa for area", data);

        // Remove handle drawing completely or set radius to 0
        if (data.handles) {
          data.handles.start.drawnIndependently = false;
          data.handles.end.drawnIndependently = false;
        }

        // Optional: you can comment out drawHandles completely if the above changes don't work
        // drawHandles(context, eventData, data.handles, handleOptions);

        if (data.invalidated === true) {
          if (data.cachedStats) {
            this.throttledUpdateCachedStats(image, element, data);
          } else {
            this.updateCachedStats(image, element, data);
          }
        }
      }
    });
  }

  mouseMoveCallback(evt) {
    const { element } = evt.detail;
    // Keep cursor default at all times
    element.style.cursor = "default";
  }

  mouseDownCallback(evt) {
    const { element, currentPoints } = evt.detail;
    const toolData = getToolState(element, this.name);

    if (!toolData) return;

    // Move all circles to clicked point immediately
    toolData.data.forEach((data) => {
      // Skip if data is not visible
      if (data.visible === false) return;

      // Update center point coordinates
      data.handles.start.x = currentPoints.image.x;
      data.handles.start.y = currentPoints.image.y;
      data.handles.end.x = currentPoints.image.x;
      data.handles.end.y = currentPoints.image.y;

      // Ensure handles are not interactive
      data.handles.start.active = false;
      data.handles.start.highlight = false;
      data.handles.end.active = false;
      data.handles.end.highlight = false;
      data.active = false;
    });

    external.cornerstone.updateImage(element);
  }

  mouseDragCallback(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    return false;
  }

  mouseUpCallback(evt) {
    // Do nothing
  }

  activeCallback(element) {
    // This method is called when the tool is activated
    this.mouseEventListeners.forEach((listener) => {
      element.addEventListener(listener.eventName, listener.listener);
    });
  }

  deactiveCallback(element) {
    // This method is called when the tool is deactivated
    this.mouseEventListeners.forEach((listener) => {
      element.removeEventListener(listener.eventName, listener.listener);
    });
  }
}
