import React, { useEffect, useRef, useState } from "react";

import { Form } from "react-formio";
import axios from "axios";
import { API_URL } from "../../../Endpoints";
import { useDispatch, useSelector } from "react-redux";

import {
  deleteUnmatchedKeys,
  displayImageOverLay,
  extractFileName,
  findAllKeys,
  findETDRSMatches,
  getAllImages,
} from "../utils/basic_utils";
import AlertDialog from "../../../Components/Dialog";
import { useLocation } from "react-router-dom";
import PreviousData from "../../../Components/previousDataButton";
import useMutationObserver from "../../../customHooks.js/useMutaitonObserver";
import { save_annotations } from "../actions/dcm_actions";

let formDataReplica = {};
let UpdateForm = false;

let submitButtonClicked = false;

export default function QAForm(props) {
  const [formData, setFormData] = useState({});
  const [formDataLoading, setFormDataLoading] = useState(true);
  const dicom_data = useSelector((state) => state.dcm);
  const dispatch = useDispatch();

  const [respMessage, setRespMessage] = useState({
    error: false,
    message: "",
  });
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const formikRef = useRef(null);

  const [UpdatedSchema, setupdatedSchema] = useState({});

  // Helper function to generate the payload object from the selected file

  const rq = props.request_info;
  const handleButtonClick = () => {
    if (
      formData?.form_schema?.components?.filter(
        (obj) =>
          (obj?.components &&
            obj?.components.some(
              (component) => component?.key === "totalArea"
            )) ||
          obj?.key === "totalArea"
      )
    ) {
      setFormData((prevState) => ({
        ...prevState,
        response: {
          ...prevState.response,
          data: {
            ...prevState.response?.data,

            totalArea: dicom_data.SelectedArea.TotalArea, // Updating the value here
          },
        },
      }));
    }

    if (
      formData?.form_schema?.components?.filter(
        (obj) =>
          (obj?.components &&
            obj?.components.some(
              (component) => component?.key === "lesions_area"
            )) ||
          obj?.key === "lesions_area"
      )
    ) {
      setFormData((prevState) => ({
        ...prevState,
        response: {
          ...prevState.response,
          data: {
            ...prevState.response?.data,
            lesions_area: dicom_data.SelectedArea.LisonsArea,
          },
        },
      }));
    }
  };
  const updateETDRSValues = () => {
    let area_versions = dicom_data?.lisonsAreasData?.area_versions;
    const pixelatedObject = area_versions?.find(
      (obj) => obj.area_type === dicom_data?.lisonOptions?.lisonAreaselected
    );
    console.log("updating these values", { pixelatedObject, area_versions });
    let values = {
      EZDA_ON_ETDRS_1MM: `${pixelatedObject?.lesions_1mm_area_mm2}  `,
      EZDA_ON_ETDRS_3MM: `${pixelatedObject?.lesions_3mm_area_mm2} `,
      EZDA_ON_ETDRS_6MM: `${pixelatedObject?.lesions_6mm_area_mm2} `,
      ETDRS_ON_OCT: `${pixelatedObject?.ETDRS_area_mm2} `,
    };

    const etdrsMatches = findETDRSMatches(
      formData?.form_schema?.components,
      values
    );

    // Only include etdrsMatches if it's not empty and has valid values
    const hasValidMatches =
      Object.keys(etdrsMatches).length > 0 &&
      Object.values(etdrsMatches).every((value) => value !== "undefined ");

    return { etdrsMatches, hasValidMatches };
  };
  const UpdateEtdrsValuesInForm = () => {
    const { etdrsMatches, hasValidMatches } = updateETDRSValues();

    // Check if values are different before updating
    const shouldUpdate =
      hasValidMatches &&
      Object.entries(etdrsMatches).some(
        ([key, value]) => formData?.response?.data?.[key] !== value
      );

    if (shouldUpdate) {
      setFormData((prevState) => ({
        ...prevState,
        response: {
          ...prevState.response,
          data: {
            ...prevState.response?.data,
            ...etdrsMatches,
          },
        },
      }));
    }
  };

  useEffect(() => {
    if (dicom_data.etdrsActive) {
      UpdateEtdrsValuesInForm();
    } else {
      const emptyValues = {
        EZDA_ON_ETDRS_1MM: " ",
        EZDA_ON_ETDRS_3MM: " ",
        EZDA_ON_ETDRS_6MM: " ",
        ETDRS_ON_OCT: " ",
      };

      // First find which ETDRS fields exist in the form
      const etdrsMatches = findETDRSMatches(
        formData?.form_schema?.components,
        emptyValues
      );

      // Only include fields that exist in the form
      const hasValidMatches = Object.keys(etdrsMatches).length > 0;

      // Check if values are different before updating
      const shouldUpdate =
        hasValidMatches &&
        Object.entries(etdrsMatches).some(
          ([key, value]) => formData?.response?.data?.[key] !== value
        );

      if (shouldUpdate) {
        setFormData((prevState) => ({
          ...prevState,
          response: {
            ...prevState.response,
            data: {
              ...prevState.response?.data,
              ...etdrsMatches,
            },
          },
        }));
      }
    }
    let area_versions = dicom_data?.lisonsAreasData?.area_versions;

    const pixelatedObject = area_versions?.find(
      (obj) => obj?.area_type === dicom_data?.lisonOptions?.lisonAreaselected
    );
    console.log("area_versions QA FORM", {
      area_versions,
      pixelatedObject,
    });
    if (
      dicom_data.SelectedArea.TotalArea !==
        formData?.response?.data?.totalArea &&
      formData
    ) {
      handleButtonClick();
    }
  }, [
    dicom_data.SelectedArea,
    dicom_data?.mainScreenShotUrl,
    formData,
    dicom_data.etdrsActive,
    dicom_data?.lisonsAreasData,
  ]);

  const loadInitialData = async () => {
    setFormDataLoading(true);

    await axios
      .get(
        `${API_URL}/api/v1/${
          props.request_info.role === "quality_control" ? "qc-form" : "qa-form"
        }?stack_id=${rq.stack_id}&user_id=${rq.user_id}${
          query.get("form_version_id")
            ? `&form_version_id=${query.get("form_version_id")}`
            : ""
        }`
      )
      .then((res) => {
        UpdateForm = true;
        const index =
          res?.data?.form_schema?.components[0]?.components.findIndex(
            (component) => component.key === "leasions_area_img"
          );

        if (index !== -1) {
          // Store the removed component in state
          const [removed] =
            res?.data?.form_schema?.components[0]?.components.splice(index, 1);
        }
        let data = res.data;
        let moreModi = {
          ...data,
          form_schema: {
            ...data.form_schema,
            // components: componentsUpdated,
          },
        };

        setFormData(moreModi);
      })
      .catch((err) => {})
      .finally(() => {
        setFormDataLoading(false);
      });
  };

  useEffect(() => {
    loadInitialData();
  }, []);

  useEffect(() => {
    console.log("formData in useEffect", formData);
    if (
      formData?.screenshot_urls?.lesions_area &&
      dicom_data?.mainScreenShotUrl === null
    ) {
      if (dicom_data?.mainScreenShotUrl === null) {
        dispatch({
          type: "mainScreenShot_url_changed",
          mainScreenShotUrl: formData.screenshot_urls.lesions_area[0],
        });
      }
    }
  }, [formData]);
  const handleExternalSubmit = () => {
    if (formikRef.current) {
      submitButtonClicked = true;
      formikRef.current.props.onSubmit(formikRef.current.props.submission);
    }
  };

  const submit_form = async (e) => {
    let filesNames, allUrlsOriginal, allUrls, deletedImagesUrls;

    if (query.get("role") !== "quality_control") {
      if (!submitButtonClicked) {
        return;
      }

      let dropZoneFiles = getAllImages(
        UpdatedSchema?.components
          ? UpdatedSchema.components
          : formData?.form_schema?.components
      );

      filesNames = { ...dropZoneFiles };
      if (formData?.screenshot_urls) {
        allUrlsOriginal = Object.values(formData?.screenshot_urls).flat();
      }

      allUrls = Object.values(dropZoneFiles).flat();

      deletedImagesUrls = allUrlsOriginal.filter(
        (url) => !allUrls.includes(url)
      );

      Object.keys(filesNames).forEach((key) => {
        filesNames[key] = filesNames[key].map(extractFileName);
      });

      if (dicom_data?.mainScreenShotUrl) {
        allUrls.push(dicom_data?.mainScreenShotUrl);
      }
    }

    if (e.files) {
      e.files = {
        ...e.files,
        ...filesNames,
        ...(dicom_data?.mainScreenShotUrl && {
          lesions_area: [extractFileName(dicom_data.mainScreenShotUrl)],
        }),
      };
    } else {
      e["files"] = {
        ...filesNames,
        ...(dicom_data?.mainScreenShotUrl && {
          lesions_area: [extractFileName(dicom_data.mainScreenShotUrl)],
        }),
      };
    }
    if (!dicom_data?.mainScreenShotUrl) {
      delete e?.data?.totalArea;
      delete e?.data?.lesions_area;
    }

    const formSchemaKeys = findAllKeys(formData?.form_schema?.components);

    deleteUnmatchedKeys(e.data, formSchemaKeys);
    console.log("before submit eee", e);
    dispatch({ type: "LoadingOverlay_changed", LoadingOverlay: true });

    // only save annotations if role= grader
    if (query.get("role") === "grader") {
      await save_annotations(
        dicom_data,
        query.get("stack_id"),
        query.get("user_id"),
        query.get("series_no"),
        query.get("role"),
        query.get("form_version_id") ? "additional_form" : "primary_form",
        query.get("form_version_id") ? query.get("form_version_id") : ""
      );
    }

    await axios
      .post(
        `${API_URL}/api/v1/${
          props.request_info.role === "quality_control" ? "qc-form" : "qa-form"
        }`,
        {
          stack_id: rq.stack_id,
          user_uuid: rq.user_id,
          responses: e,
          form_type: query.get("form_version_id")
            ? "additional_form"
            : "primary_form",
          form_version_id: formData.form_version_id,
          screenshot_urls: allUrls,

          deleted_screenshot_urls: deletedImagesUrls,
          status: "completed",
          disclaimer: false,
        }
      )
      .then((res) => {
        setRespMessage({ error: false, message: "Data saved successfully" });
      })
      .catch((err) => {
        setRespMessage({ error: true, message: "Unable to save data" });
      })
      .finally(() => {
        dispatch({ type: "LoadingOverlay_changed", LoadingOverlay: false });
        submitButtonClicked = false;
      });
  };

  useMutationObserver(formData, setupdatedSchema, formDataReplica, UpdateForm);
  if (formDataLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center", // Added to center vertically
          height: "100%", // Added to center vertically
        }}
      >
        <h3>Loading...</h3>
      </div>
    );
  }
  return (
    <div
      style={{
        backgroundColor: "#fff",
        padding: "0px 10px",
        overflow: "auto",
        height: "100%",
      }}
    >
      {formData?.previous_visit_data?.previous_record?.data ? (
        <PreviousData formData={formData} />
      ) : null}

      {respMessage.message !== "" ? (
        <AlertDialog
          open={respMessage.message === "" ? false : true}
          handleClose={() => {
            setRespMessage({ error: false, message: "" });
          }}
          message={respMessage.message}
          error={respMessage.error}
        />
      ) : null}
      {formData?.form_schema && (
        <Form
          key={UpdatedSchema ? "updated" : "initial"}
          form={
            UpdatedSchema?.components ? UpdatedSchema : formData.form_schema
          }
          submission={formData.response}
          onSubmit={submit_form}
          ref={formikRef}
          options={{
            noDefaultSubmitButton: true, // This prevents default form submission
            hooks: {
              beforeSubmit: function (submission, callback) {
                callback(null, submission); // Manually handle form submission
              },
            },
          }}
        ></Form>
      )}
      {formData ? (
        <>
          <div
            style={{
              flexDirection: "column",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "45px",
              height: "300px",
            }}
          >
            {dicom_data?.mainScreenShotUrl ? (
              <img
                style={{ cursor: "pointer" }}
                onClick={() => {
                  displayImageOverLay(dicom_data?.mainScreenShotUrl, dispatch);
                }}
                src={dicom_data?.mainScreenShotUrl}
                alt="ScreenShot"
                height="200px"
                width="200px"
              />
            ) : null}
            {console.log(
              "disss",
              dicom_data.dicom_data[query.get("stack_id")].OP === null ||
                dicom_data.dicom_data[query.get("stack_id")].OPT === null
            )}
            <button
              onClick={handleExternalSubmit}
              disabled={
                dicom_data?.submitDisable ||
                dicom_data.dicom_data[query.get("stack_id")].OP === null ||
                dicom_data.dicom_data[query.get("stack_id")].OPT === null
              }
              type="submit"
              style={{
                backgroundColor:
                  dicom_data?.submitDisable ||
                  dicom_data.dicom_data[query.get("stack_id")].OP === null ||
                  dicom_data.dicom_data[query.get("stack_id")].OPT === null
                    ? "gray"
                    : "#1565C0",
                color: "white",
                border: "0px",
                borderRadius: "5px",
                width: "250px",
                height: "50px",
                cursor: "pointer",
              }}
            >
              Submit
            </button>
          </div>
        </>
      ) : (
        <div>Unable to load form</div>
      )}

      {respMessage.message !== "" && <div>{respMessage.message}</div>}
    </div>
  );
}
