export const get_slope_from_line = (line) => {
  let x1 = line[0];
  let y1 = line[1];
  let x2 = line[2];
  let y2 = line[3];

  let m = (y2 - y1) / (x2 - x1);
  return m;
};
export const extractFileName = (url) => {
  // Split the URL by '/' and get the last segment
  const segments = url.split("/");
  const lastSegment = segments.pop();

  // Decode the URL-encoded string
  const fileName = decodeURIComponent(lastSegment);

  return fileName;
};

export const findETDRSMatches = (arr, values, matches = {}) => {
  arr?.forEach((obj) => {
    // Check if object has data-etdrs attribute that matches a key in values
    if (
      obj?.attributes?.["data-etdrs"] &&
      values[obj.attributes["data-etdrs"]]
    ) {
      matches[obj.key] = values[obj.attributes["data-etdrs"]];
    }

    // Recursively search in nested components
    if (Array.isArray(obj?.components)) {
      findETDRSMatches(obj.components, values, matches);
    }
  });

  return matches;
};
export const deleteUnmatchedKeys = (obj, allowedKeys) => {
  // Iterate over keys in obj
  Object.keys(obj).forEach((key) => {
    // Check if the key is not in the allowed keys array
    if (!allowedKeys.includes(key)) {
      delete obj[key];
    }
  });
  return obj;
};

export const findObjectByKey = (arr, key) => {
  for (const obj of arr) {
    // Check if the object has the key we're looking for
    if (obj?.key === key) {
      return obj;
    }

    // Recursively search in nested components if present
    if (Array.isArray(obj?.components)) {
      const found = findObjectByKey(obj.components, key);
      if (found) {
        return found;
      }
    }
  }
  return null; // Return null if no object with the key is found
};
export const findAllKeys = (arr, keys = []) => {
  arr?.forEach((obj) => {
    // Add the key if it exists
    if (obj?.key) {
      keys.push(obj.key);
    }

    // Recursively search in nested components if present
    if (Array.isArray(obj?.components)) {
      findAllKeys(obj?.components, keys);
    }
  });

  return keys;
};

export const intercept_from_line = (line) => {
  let x1 = line[0];
  let y1 = line[1];

  let m = get_slope_from_line(line);
  let b = y1 - m * x1;
  return b;
};

export const get_normalized_x = (e, currentFrameData, image_width) => {
  let xmin = currentFrameData[0];
  let xmax = currentFrameData[2];

  let total_width = xmax - xmin;

  let normalizedX =
    (e.detail.currentPoints.image.x / image_width) * total_width + xmin;

  if (normalizedX < xmin || normalizedX > xmax) {
    return null;
  }

  return normalizedX;
};

export function is_inside_polygon(point, vs) {
  // ray-casting algorithm based on
  // https://wrf.ecse.rpi.edu/Research/Short_Notes/pnpoly.html/pnpoly.html

  var x = point[0],
    y = point[1];

  var inside = false;
  for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
    var xi = vs[i][0],
      yi = vs[i][1];
    var xj = vs[j][0],
      yj = vs[j][1];

    var intersect =
      yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }

  return inside;
}

/**
 * Handles click events on the dropzone area
 * @param {Event} event - The click event
 * @param {Function} setupdatedSchema - Function to update the schema
 * @param {Object} formData - The current form data
 * @param {boolean} changeFlag - Flag to indicate if changes are allowed
 * @param {Function} dispatch - Dispatch function for state updates
 */
export const handleDropzoneClick = (
  event,
  setupdatedSchema,
  formData,
  changeFlag,
  dispatch
) => {
  const inputElementPro =
    event.currentTarget.querySelector("input[type='file']");
  const target = event.target;

  // Handle image click
  if (target.tagName === "IMG") {
    const imgSrc = event.target.src;
    if (imgSrc) {
      displayImageOverLay(imgSrc, dispatch);
    }
  }
  // Handle remove button click
  else if (target.textContent.trim() === "X") {
    const thumbnailDiv = target.closest(".thumbnail");
    if (thumbnailDiv) {
      const imgElement = thumbnailDiv.querySelector("img");
      if (imgElement) {
        const imgSrc = imgElement.src;
        const dataQuestion = inputElementPro.dataset.question;

        // Update schema to remove the image
        setupdatedSchema((prev) => ({
          components: findAndReplaceObjectByKey(
            prev?.components
              ? prev?.components
              : formData?.form_schema?.components ||
                  formData?.adjudication_form_schema?.components,
            dataQuestion,
            imgSrc,
            "delete"
          ),
        }));
      }
    }
  }
  // Handle dropzone click to open file dialog
  else {
    changeFlag = true;
    if (inputElementPro) {
      inputElementPro.click();
    }
  }
};
function replaceImageFromContent(object, key, imgSrc) {
  if (imgSrc) {
    // Create a temporary DOM element to hold the HTML content
    const tempElement = document.createElement("div");

    // Set the innerHTML to the content from your object
    tempElement.innerHTML = object.content;

    // Find all img tags within the content

    const escapedKey = key.replace(/\./g, "\\.");

    const imgTags = tempElement.querySelectorAll("img");
    const progressBarContainerAll = tempElement.querySelectorAll("div");

    const removeButton = Array.from(progressBarContainerAll).find(
      (img) => img.id === key + "removeButton"
    );
    const progressBarContainer = Array.from(progressBarContainerAll).find(
      (img) => img.id === key + "progressBarContainer"
    );

    if (removeButton) removeButton.style.display = "block";
    if (progressBarContainer) progressBarContainer.style.display = "none";
    // Convert the NodeList to an array and find the img tag with the specific id
    const targetImg = Array.from(imgTags).find((img) => img.id === key);

    if (targetImg) {
      targetImg.src = imgSrc;

      // Update the content of the object with the modified HTML
      object.content = tempElement.innerHTML;

      return object;
    }
    // const imgTag = tempElement.querySelector(`#${key}`);
  }

  return object;
}
function removeImageFromContent(object, imgSrcToRemove) {
  // Create a temporary DOM element to hold the HTML content
  const tempElement = document.createElement("div");

  // Set the innerHTML to the content from your object
  tempElement.innerHTML = object.content;

  // Find all img tags within the content
  const imgTags = tempElement.querySelectorAll("img");

  // Loop through img tags and remove the one with the specified src
  imgTags.forEach((img) => {
    if (img.src === imgSrcToRemove) {
      img.parentElement.remove(); // Removing the parent div (thumbnail) containing the img
    }
  });

  // Update the content of the object with the modified HTML
  object.content = tempElement.innerHTML;

  return object;
}

/**
 * Finds and replaces objects in an array based on a key
 * @param {Array} arr - The array to search
 * @param {string} targetKey - The key to search for
 * @param {string} newContent - The new content to replace with
 * @param {string} toDo - The action to perform (delete, update, or add)
 * @returns {Array} - The updated array
 */
export const findAndReplaceObjectByKey = (arr, targetKey, newContent, toDo) => {
  return arr?.map((obj) => {
    const regex = new RegExp(`data-question\\s*=\\s*["']${targetKey}["']`);
    const urlPattern = /^https?:\/\/[^\s/$.?#].[^\s]*$/i;

    // Handle image deletion
    if (
      obj?.content &&
      regex.test(obj.content) &&
      urlPattern.test(newContent) &&
      toDo === "delete"
    ) {
      const updatedObject = removeImageFromContent({ ...obj }, newContent);
      return {
        ...updatedObject,
      };
    }

    // Handle content update
    if (obj?.content && toDo === "update") {
      const replacedObject = replaceImageFromContent(
        { ...obj },
        targetKey,
        newContent
      );
      return {
        ...replacedObject,
      };
    }

    // Handle content addition
    if (obj?.content && regex.test(obj.content) && toDo === "add") {
      return {
        ...obj,
        content: newContent,
      };
    }

    // Recursively search nested components
    if (Array.isArray(obj.components)) {
      return {
        ...obj,
        components: findAndReplaceObjectByKey(
          obj.components,
          targetKey,
          newContent,
          toDo
        ),
      };
    }

    return obj;
  });
};

export const addImageInDropzone = (
  container,
  dataQuestion,
  fileName,
  imageFiles,
  setupdatedSchema,
  formData,
  changeFlag,
  dispatch
) => {
  let call = false;
  if (container.content) {
    call = true;
    const tempElement = document.createElement("div");

    // Set the innerHTML to the content from your object
    tempElement.innerHTML = container.content;

    container = tempElement.querySelector(
      ".dropzone.dropzone-default.dz-clickable"
    );
  }

  const virtualContainer = document.createElement("div");
  container.addEventListener("click", (event) => {
    handleDropzoneClick(
      event,
      setupdatedSchema,
      formData,
      changeFlag,
      dispatch
    );
  });

  ///
  const inputElement = container.querySelector(
    `input[data-question=${dataQuestion}]`
  );

  if (inputElement) {
    imageFiles?.urls?.forEach((file) => {
      if (fileName === "") {
        fileName = file.substring(file.lastIndexOf("/") + 1);
      }

      // Create a container div for the thumbnail and close button
      const thumbnailDiv = document.createElement("div");
      thumbnailDiv.className = "thumbnail";
      thumbnailDiv.style.margin = "10px";
      thumbnailDiv.style.display = "inline-block";
      thumbnailDiv.style.position = "relative";

      // Create an img element for the thumbnail
      const imgElement = document.createElement("img");
      imgElement.src = file;

      imgElement.alt = "Preview";
      imgElement.style.width = "120px";
      imgElement.style.height = "120px";
      imgElement.style.objectFit = "cover";
      imgElement.style.border = "1px solid #ddd";
      imgElement.id = dataQuestion + "_" + fileName;

      const progressBarContainer = document.createElement("div");
      progressBarContainer.style.position = "absolute";
      progressBarContainer.style.top = "50%";
      progressBarContainer.style.left = "50%";
      progressBarContainer.style.transform = "translate(-50%, -50%)";
      progressBarContainer.style.width = "80%";
      progressBarContainer.style.backgroundColor = "#e0e0df";
      progressBarContainer.style.borderRadius = "10px";
      progressBarContainer.style.overflow = "hidden";
      progressBarContainer.style.height = "15px";
      //
      progressBarContainer.id =
        dataQuestion + "_" + fileName + "progressBarContainer";

      /// progress bar
      const progressBar = document.createElement("div");
      progressBar.id = dataQuestion + "_" + fileName + "progressBar";
      progressBar.style.width = "35%"; // This would be dynamic based on upload progress
      progressBar.style.height = "100%";
      progressBar.style.backgroundColor = "#1565C0";
      progressBar.style.textAlign = "center";
      progressBar.style.color = "blue";
      progressBar.style.lineHeight = "11px";
      progressBar.style.fontWeight = "bold";
      //
      // progressBar.textContent = "35%"; // This should also be dynamic

      progressBarContainer.appendChild(progressBar);
      // Create a button element for removing the preview
      const removeButton = document.createElement("div");
      removeButton.textContent = "X";
      removeButton.style.position = "absolute";
      removeButton.style.top = "0";
      removeButton.style.right = "0";
      removeButton.style.background = "black";
      removeButton.style.color = "#fff";
      removeButton.style.border = "none";
      removeButton.style.cursor = "pointer";
      removeButton.style.borderRadius = "50%";
      removeButton.style.width = "27px";
      removeButton.style.height = "27px";
      removeButton.id = dataQuestion + "_" + fileName + "removeButton";

      if (
        file !==
        "https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png" //image place holder url
      ) {
        progressBarContainer.style.display = "none";
        // progressBarContainer.style.display = "none";
      } else {
        removeButton.style.display = "none";
      }

      // Append img and button to the thumbnail div
      thumbnailDiv.appendChild(imgElement);

      thumbnailDiv.appendChild(progressBarContainer);
      thumbnailDiv.appendChild(removeButton);

      // Instead of appending to the UI container, append it to virtualContainer
      container.appendChild(thumbnailDiv);
      virtualContainer.appendChild(container);

      if (!call) {
        // debugger;

        setupdatedSchema((prev) => ({
          components: findAndReplaceObjectByKey(
            prev?.components
              ? prev?.components
              : formData?.form_schema?.components ||
                  formData?.adjudication_form_schema?.components,
            dataQuestion,
            virtualContainer.innerHTML,
            "add"
          ),
        }));
      }

      /// this virtualContainer.innerHTML should be returned an should be called from outside
    });
  }

  return virtualContainer.innerHTML;
};

/**
 * Handles file change events for file uploads
 * @param {Event} event - The file change event
 * @param {string} Question - The question identifier
 * @param {boolean} changeFlag - Flag to indicate if changes are allowed
 * @param {Function} setProgress - Function to update progress state
 * @param {Object} progress - The current progress state
 * @param {Function} addImageFiles - Function to add image files to the state
 * @param {Function} sendFileUpload - Function to send file upload request
 * @param {Function} updateState - Function to update the overall state
 */
export const handleFileChange = async (
  event,
  Question,
  changeFlag,
  setProgress,
  progress,
  addImageFiles,
  sendFileUpload,
  stack_id,
  updateState
) => {
  if (changeFlag) {
    changeFlag = false;

    const files = Array.from(event.target.files);
    let urllGot = URL.createObjectURL(files[0]);

    // Function to update progress for individual files
    function progressChange(key, value) {
      let obj = progress;
      obj[key] = value;
      setProgress((prev) => ({ ...prev, key: value }));
    }

    // Process each file
    const uploadPromises = files?.map(async (singleFile) => {
      let obj = {
        urls: [
          "https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png",
        ],
      };

      // Add image file to state
      addImageFiles(obj, Question, singleFile?.name);

      // Send file upload request

      await sendFileUpload(
        stack_id,
        singleFile,
        progressChange,
        Question,
        updateState
      );
    });

    // Wait for all the upload promises to complete
    await Promise.all(uploadPromises);
  }
};
export const findDropZone = (
  formData,
  changeFlag,
  fileInputRef,
  setProgress,
  progress,
  addImageFiles,
  sendFileUpload,
  stack_id,
  updateState,
  setupdatedSchema,
  dispatch
) => {
  //removing the choose file button
  // Check if the element exists, then add styles
  setTimeout(() => {
    const dropzoneDivs = document.querySelectorAll(
      ".dropzone.dropzone-default.dz-clickable"
    );

    dropzoneDivs.forEach((dropzoneDiv) => {
      // Find the input element inside the current dropzone div
      const inputElementPro = dropzoneDiv.querySelector("input[type='file']");

      if (inputElementPro) {
        if (inputElementPro.hasAttribute("disabled")) {
          // Remove disabled attribute
          inputElementPro.removeAttribute("disabled");

          inputElementPro.setAttribute("multiple", "");
        }

        // Log elements for debugging

        // Reference the file input
        fileInputRef.current = inputElementPro;

        // Add event listeners to the dropzone and input

        inputElementPro.style.display = "none"; // Hide the input element if needed

        if (!dropzoneDiv.hasListeners) {
          dropzoneDiv.addEventListener("click", (event) => {
            handleDropzoneClick(
              event,
              setupdatedSchema,
              formData,
              changeFlag,
              dispatch
            );
          });
          ["dragenter", "dragover", "dragleave", "drop"].forEach(
            (eventName) => {
              dropzoneDiv.addEventListener(eventName, (event) => {
                event.preventDefault();
              });
            }
          );
          dropzoneDiv.addEventListener(
            "dragover",
            (event) => {
              event.preventDefault();
              // virtualContainer.classList.add("drag-over"); // Optional: Add visual feedback
            },
            { once: true }
          );

          dropzoneDiv.addEventListener(
            "drop",
            (event) => {
              event.preventDefault(); // Prevent default behavior (open as link for some elements)
              const files = Array.from(event.dataTransfer.files);

              const droppedFiles = Array.from(event.dataTransfer.files);

              if (droppedFiles.length > 0) {
                changeFlag = true;
                handleFileChange(
                  { target: { files: droppedFiles } }, // Simulate a file input event
                  inputElementPro.getAttribute("data-question"), // Assuming data-question attribute is on container
                  // { ...formData },
                  changeFlag,
                  setProgress,
                  progress,
                  addImageFiles,
                  sendFileUpload,
                  stack_id,
                  updateState
                );
              }
            },
            { once: true }
          );

          inputElementPro.addEventListener(
            "change",
            (event) => {
              changeFlag = true;

              handleFileChange(
                event,
                inputElementPro.getAttribute("data-question"),
                // { ...formData }
                changeFlag,
                setProgress,
                progress,
                addImageFiles,
                sendFileUpload,
                stack_id,
                updateState
              );
            },
            { once: true }
          );
          dropzoneDiv.hasListeners = true;
        }
      }
    });
  }, 100);
  //finding the input Element and dropzone to add click and onChange event handlers
};

export const displayImageOverLay = (url, dispatch) => {
  dispatch({
    type: "displayScreenShot_url_changed",
    displayScreenShotUrl: url,
  });
  dispatch({ type: "LoadingOverlay_changed", LoadingOverlay: true });
  dispatch({
    type: "screenShot_Display_changed",
    screenShotDisplay: true,
  });
};

export const findAndReplacePopulate = (
  arr,
  targetKey,
  imageFiles,
  setupdatedSchema,
  formData,
  changeFlag,
  dispatch
) => {
  const regex = new RegExp(`data-question\\s*=\\s*["']${targetKey}["']`);
  // Iterate through the array of objects
  return arr?.map((obj) => {
    // adding new html elements
    if (obj?.content && regex.test(obj.content)) {
      return {
        ...obj,
        content: addImageInDropzone(
          obj,
          targetKey,
          "",
          imageFiles,
          setupdatedSchema,
          formData,
          changeFlag,
          dispatch
        ),
      };
    }

    // If the object has nested components, recursively search inside it
    if (Array.isArray(obj.components)) {
      return {
        ...obj,
        components: findAndReplacePopulate(
          obj.components,
          targetKey,
          imageFiles,
          setupdatedSchema,
          formData,
          changeFlag,
          dispatch
        ), // Recursively update nested components
      };
    }

    // Return the object unchanged if no match or nested components are found
    return obj;
  });
};

export function getAllImages(components) {
  const imageMap = {};

  components.forEach((obj) => {
    // Check if 'content' property exists and contains a dropzone class
    if (obj.content && obj.content.includes("dropzone")) {
      // Use regex to find image URLs in the 'content' field
      const imgTags = obj.content.match(/<img [^>]*src="([^"]*)"[^>]*>/g);
      const dataQuestion = obj.content.match(/data-question="([^"]*)"/);

      if (imgTags && dataQuestion) {
        const urls = imgTags
          .map((imgTag) => {
            // Extract the URL from the image tag
            const urlMatch = imgTag.match(/src="([^"]*)"/);
            return urlMatch ? urlMatch[1] : null;
          })
          .filter((url) => url !== null);

        // Add to the result object with the data-question key
        const questionKey = dataQuestion[1];
        imageMap[questionKey] = urls;
      }
    }

    // Recursively check nested components
    if (Array.isArray(obj.components)) {
      const nestedImages = getAllImages(obj.components);
      Object.assign(imageMap, nestedImages); // Merge with the current imageMap
    }
  });

  return imageMap;
}
