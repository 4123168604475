import cornerstoneWADOImageLoader from "cornerstone-wado-image-loader";
import {
  processOPTDicomMetaData,
  processOPDicomMetaData,
  processElement,
  calc_angle_deg,
} from "../utils/dicom_utils";
import cornerstoneTools from "cornerstone-tools";
import axios from "axios";
import { API_URL } from "../../../Endpoints";

function check_followup_dicom(base_dcm_data, child_dcm_data, dcm_id) {
  /*
    Checks if child dicom is a follow up scan of base dicom file
  */
  if (child_dcm_data === null) {
    return true;
  }
  var isFollowUp = true;
  if (
    base_dcm_data.metadata.frames.length !==
    child_dcm_data.metadata.frames.length
  ) {
    // If number of frames in both dicoms are not equal, it is not followup
    isFollowUp = false;
  } else {
    // If number of frames in both dicoms are equal, compare slice coordinates for each frame and if they are on same place, mark as followup
    for (var i = 0; i < base_dcm_data.metadata.frames.length; i++) {
      if (
        base_dcm_data.metadata.frames[i][0] !==
        child_dcm_data.metadata.frames[i][0]
      ) {
        // Check first point
        isFollowUp = false;
      }
    }
  }
  return isFollowUp;
}

export async function fetch_dicom_metdata(
  dicom_urls,
  scheme,
  dcm_id,
  dispatch,
  dicom_data
) {
  let OPT_DATA = null;
  let OP_DATA = null;
  await cornerstoneWADOImageLoader.wadouri.dataSetCacheManager
    .load(dicom_urls[0], cornerstoneWADOImageLoader.internal.xhrRequest)
    .then(function (dataSet) {
      if (
        processElement(dataSet.elements.x00080060, dataSet.byteArray).trim() ===
        "OPT"
      ) {
        // If the image is an OCT (OPT)
        OPT_DATA = processOPTDicomMetaData(dataSet, scheme, dicom_urls[0]);
        OPT_DATA.image_id = "wadouri:" + dicom_urls[0];
        OPT_DATA.url = dicom_urls[0];
      } else {
        // If it is not an OPPT, then it is an OP
        OP_DATA = processOPDicomMetaData(dataSet, scheme, dicom_urls[0]);
        OP_DATA.image_id = "wadouri:" + dicom_urls[0];
        OP_DATA.url = dicom_urls[0];
      }
    });

  await cornerstoneWADOImageLoader.wadouri.dataSetCacheManager
    .load(dicom_urls[1], cornerstoneWADOImageLoader.internal.xhrRequest)
    .then(function (dataSet) {
      if (
        processElement(dataSet.elements.x00080060, dataSet.byteArray).trim() ===
        "OPT"
      ) {
        // If the image is an OCT (OPT)
        OPT_DATA = processOPTDicomMetaData(dataSet, scheme, dicom_urls[1]);
        OPT_DATA.image_id = "wadouri:" + dicom_urls[1];
        OPT_DATA.url = dicom_urls[1];
      } else {
        // If it is not an OPPT, then it is an OP
        OP_DATA = processOPDicomMetaData(dataSet, scheme, dicom_urls[1]);
        OP_DATA.image_id = "wadouri:" + dicom_urls[1];
        OP_DATA.url = dicom_urls[1];
      }
    });

  let angle = calc_angle_deg(
    OPT_DATA.metadata.frames[OPT_DATA.metadata.frames.length - 1],
    OP_DATA.metadata.pixel_spacing
  );
  OPT_DATA.metadata.rect_angle = angle;

  var parentDcmData = null;
  // If there is already a stack added, we check if the new stack is a followup or not
  if (Object.keys(dicom_data).length > 0) {
    Object.keys(dicom_data).forEach(function (key) {
      if (key !== dcm_id) {
        parentDcmData = dicom_data[key].OPT;
      }
    });
  } else {
  }

  let isFollowUp = check_followup_dicom(OPT_DATA, parentDcmData, dcm_id);
  if (Object.keys(dicom_data).length > 0) {
    dispatch({
      type: "DICOM_CAN_SYNC_SCANS",
      canSyncScans: isFollowUp,
    });
  }

  dispatch({
    type: "DICOM_FETCH_META_SUCCESS",
    dicom_data: {
      OP: OP_DATA,
      OPT: OPT_DATA,
      loading: false,
      isValidDcm: isFollowUp,
    },
    id: dcm_id,
  });
}

function get_OP_image_data(singleStack) {
  let imageAnnotations = singleStack?.annotations;
  var annotation_data = {};
  Object.entries(imageAnnotations).forEach(([key, value]) => {
    if (value !== undefined) {
      annotation_data[key] = value;
    }
  });

  return annotation_data;
}
export function extractPointsCoordinates(inputArray) {
  const points_coordinates = [];

  for (const frameUrl in inputArray) {
    if (inputArray.hasOwnProperty(frameUrl)) {
      const frameData = inputArray[frameUrl];

      if (
        frameData.Lison &&
        frameData.Lison.data &&
        frameData.Lison.data.length > 0
      ) {
        const frameNumber = frameUrl.match(/frame=(\d+)/)[1];

        const linesData = frameData.Lison.data.map((line, index) => ({
          line_id: index + 1,
          start_x_coor: Math.floor(line.handles.start.x),
          end_x_coor: Math.floor(line.handles.end.x),
        }));

        points_coordinates.push({
          b_scan_id: parseInt(frameNumber),
          lines: linesData,
        });
      }
    }
  }

  return points_coordinates;
}
function get_OPT_image_data(singleStack, drawingTools) {
  var imgIds = singleStack.stack.imageIds;
  var frames = {};
  for (var i = 0; i < imgIds.length; i++) {
    // Get frame annotations for all tools
    var frameAnnotations = {};
    var containsAnyData = false;
    drawingTools.forEach(function (tool) {
      var toolAnnotations =
        cornerstoneTools.globalImageIdSpecificToolStateManager.getImageIdToolState(
          imgIds[i],
          tool
        );
      if (toolAnnotations !== undefined) {
        frameAnnotations[tool] = toolAnnotations;
        containsAnyData = true;
      }
    });
    if (containsAnyData) {
      frames[imgIds[i]] = frameAnnotations;
    }
  }
  return frames;
}

export function get_annotations(dicom_data, drawingTools) {
  /*
    Gets annotations from dicom data
  */
  var annotations = {};
  Object.entries(dicom_data).forEach(([key, singleStack]) => {
    let brushToolData = {};
    // singleStack.OPT?.annotations?.Brush.data[1].labelmaps2D.forEach(function (
    //   labelmap,
    //   index
    // ) {
    //   let pixelData = labelmap.pixelData;
    //   //let segments = labelmap.segmentsOnLabelmap;
    //   // Convert pixel data to array
    //   let pixelDataArray = [];
    //   for (var i = 0; i < pixelData.length; i++) {
    //     pixelDataArray.push(pixelData[i]);
    //   }
    //   brushToolData[index] = pixelDataArray;
    // });

    var stack_data = {
      OP: get_OP_image_data(singleStack.OP),
      OPT: get_OPT_image_data(singleStack.OPT, drawingTools),
      annots: brushToolData,
      meta: singleStack.OPT.metadata.meta,
    };
    annotations[key] = stack_data;
  });
  return annotations;
}
export function veriticalMaxDistance(pointsArray) {
  const verticalDistances = [];
  for (let i = 1; i < pointsArray.length; i++) {
    const startYDistance = pointsArray[i - 1][1] - pointsArray[i][1];
    const endYDistance = pointsArray[i - 1][3] - pointsArray[i][3];
    verticalDistances.push([startYDistance, endYDistance]);
  }

  const flattenedData = verticalDistances.flat();
  const maxValue = Math.max(...flattenedData);

  return maxValue;
}

export async function opMetadataFetch(opUrl) {
  try {
    const resp = await axios.post(`${API_URL}/api/v1/dicom/meta`, {
      url: opUrl,
    });

    return resp.data;
  } catch (err) {
    return "error A";
  }
}

export function opPoints(lisonLines, opData, starting_points, ending_points) {
  for (let i = 0; i < lisonLines.length; i++) {
    for (let j = 0; j < lisonLines[i].lines.length; j++) {
      let frame_coords = opData.OPT.metadata.frames[lisonLines[i].b_scan_id];

      let optWidth = opData.OPT?.metadata?.meta?.width;

      let Framecoords = opData?.OPT?.metadata?.frames[lisonLines[i]?.b_scan_id];

      const dx = Framecoords[2] - Framecoords[0];
      const dy = Framecoords[3] - Framecoords[1];
      let redLineLength = Math.sqrt(dx * dx + dy * dy);

      let scalingFactor = redLineLength / optWidth;

      // calculate the coordinates of the point on the oblique line placed in cartesian grid by knowing the distance from starting poing

      // step : 1 calculating slope of line
      // const slope =
      //   (frame_coords[3] - frame_coords[1]) /
      //   (frame_coords[2] - frame_coords[0]);

      // step : 2 Direction vector (x2-x1,y2-y1)
      // let directionVector = [
      //   frame_coords[2] - frame_coords[0],
      //   frame_coords[3] - frame_coords[1],
      // ];

      // step :3 Length of direction vector sqrt((x2-x1)^2,(y2-y1)^2) which is same as redLineLength we already calculated above

      // step : 4 Unit vector (x2-x1/length,y2-y1/length)
      let unitVector = [
        (frame_coords[2] - frame_coords[0]) / redLineLength,
        (frame_coords[3] - frame_coords[1]) / redLineLength,
      ];

      let newStartPoint = [
        frame_coords[0] +
          lisonLines[i].lines[j].start_x_coor * scalingFactor * unitVector[0],

        frame_coords[1] +
          lisonLines[i].lines[j].start_x_coor * scalingFactor * unitVector[1],
      ];

      let newEndPoint = [
        frame_coords[0] +
          lisonLines[i].lines[j].end_x_coor * scalingFactor * unitVector[0],

        frame_coords[1] +
          lisonLines[i].lines[j].end_x_coor * scalingFactor * unitVector[1],
      ];

      starting_points.push([
        parseInt(newStartPoint[0]),
        parseInt(newStartPoint[1]),
      ]);

      ending_points.push([parseInt(newEndPoint[0]), parseInt(newEndPoint[1])]);
    }
  }
}

async function fetchMlLisonData(payload) {
  try {
    const resp = await axios.post(`${API_URL}/api/v1/ml-lison-data`, {
      inputData: payload,
    });

    return resp.data;
  } catch (err) {
    return "error EZDA API call", err;
  }
}
export async function Submit_Lison_annotations(
  dicom_data,
  stack_id,
  SaveInputLisonData,
  mlDataUpdated
) {
  var annotations = get_annotations(
    dicom_data.dicom_data,
    dicom_data.drawingTools
  );

  console.log("annotations", annotations);
  var stack_ids = Object.keys(annotations);
  let etdrsToolData = null;
  if (annotations[stack_ids[0]]?.OP["Etdrs"]) {
    etdrsToolData =
      annotations[stack_ids[0]]?.OP["Etdrs"]?.data[0]?.handles.start;
  }

  const opData = dicom_data.dicom_data[stack_id];
  let lisonLines = extractPointsCoordinates(annotations[stack_ids[0]].OPT);
  console.log("lisonLines", lisonLines);
  let starting_points = [];
  let ending_points = [];
  opPoints(lisonLines, opData, starting_points, ending_points);

  let opMetaData = await opMetadataFetch(opData.OP.url);

  let ir_bscan_distance_ = veriticalMaxDistance(opData.OPT.metadata.frames);
  // const foundObject = opMetaData.find(obj => obj.name === "Columns");

  let pixel_spacing = opMetaData.find(
    (obj) => obj.name === "Pixel Spacing"
  ).Value;
  let ir_image_size = [
    opMetaData.find((obj) => obj.name === "Columns").Value,
    opMetaData.find((obj) => obj.name === "Rows").Value,
  ];
  const xCoordinates = opData?.OPT?.metadata?.rect_poly.map(
    (vertex) => vertex[0]
  );
  const yCoordinates = opData?.OPT?.metadata?.rect_poly.map(
    (vertex) => vertex[1]
  );

  // Calculate center coordinates

  // here adding the centre coordinates of etdrs circles

  // const etdrsToolState = cornerstoneTools.getToolState(dcmRef.current, "Etdrs");
  const centerX = xCoordinates.reduce((sum, x) => sum + x, 0) / 4;
  const centerY = yCoordinates.reduce((sum, y) => sum + y, 0) / 4;
  let finalData = {
    ir_ez_lines: [
      {
        start_points: starting_points,
        end_points: ending_points,
      },
    ],
    ir_scale_x: pixel_spacing[0],
    ir_scale_y: pixel_spacing[1],
    ir_bscan_distance_px: ir_bscan_distance_,
    ir_image_size: ir_image_size,
    etdrs_center: [
      parseInt(etdrsToolData ? etdrsToolData.x : centerX),
      parseInt(etdrsToolData ? etdrsToolData.y : centerY),
    ],
    bscan_rectangle_corners: opData?.OPT?.metadata?.rect_poly,
  };

  let mlAPIPayLoad = [];
  mlAPIPayLoad.push(finalData);

  let receivedData = await fetchMlLisonData(mlAPIPayLoad);

  mlDataUpdated(mlAPIPayLoad, receivedData);
  //here have to add check for successful api call and success response only then call this function
  SaveInputLisonData(lisonLines);

  return receivedData;
}
export async function sendFileUpload(
  id,
  file,
  progressCall,
  dataQuestion,
  updateState
) {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("id", id);
  try {
    const response = await axios.post(
      `${API_URL}/api/v1/file-upload`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );

          var progressBar = document.getElementById(
            dataQuestion + "_" + file.name + "progressBar"
          );
          var progressBarContainer = document.getElementById(
            dataQuestion + "_" + file.name + "progressBarContainer"
          );

          // Change the HTML content of the div
          if (progressBar && progressBarContainer) {
            progressBar.textContent = `${percentCompleted}%`; // Update the displayed percentage
            progressBar.style.width = `${percentCompleted}%`;

            if (percentCompleted === 100) {
              // progressBar.style.display = "none";

              progressBarContainer.style.display = "none";
            } else {
              // progressBarContainer.style.display = "block";
              // progressBar.style.display = "block";
            }
          }

          // progressCall(dataQuestion, percentCompleted);

          // if (onUploadProgress) {
          //   onUploadProgress((prev) => ({
          //     ...prev,
          //     progressKey: percentCompleted,
          //   }));
          // }
        },
      }
    );
    if (!dataQuestion) {
      return response.data;
    }

    let imgElement = document.getElementById(dataQuestion + "_" + file.name);

    // Replace the src attribute with the new URL
    if (imgElement) {
      imgElement.src = response.data.urls[0];
    }

    updateState(dataQuestion + "_" + file.name, response.data.urls[0]);

    return;
  } catch (error) {
    console.error("Error uploading file:", error);
  }
}

export function processAnnotations(dicomData) {
  var annotations = get_annotations(
    dicomData.dicom_data,
    dicomData.drawingTools
  );
  console.log("savee annotations annotations", annotations);

  var stack_ids = Object.keys(annotations);

  if (!dicomData?.etdrsActive && annotations[stack_ids[0]]?.OP?.Etdrs) {
    delete annotations[stack_ids[0]].OP.Etdrs;
  }

  if (annotations[stack_ids[0]].OP.MultiPointProbe) {
    delete annotations[stack_ids[0]].OP.MultiPointProbe;
  }

  if (annotations[stack_ids[0]].OP.MultiLine) {
    delete annotations[stack_ids[0]].OP.MultiLine;
  }

  if (dicomData?.lisonsAreasData && dicomData?.LisonActive) {
    annotations[stack_ids[0]].OP["lisonsAreasData"] =
      dicomData?.lisonsAreasData;
  }
  delete annotations[stack_ids[0]].annots;
  return annotations[stack_ids[0]];
}
export async function save_annotations(
  dicom_data,
  stack_id,
  user_id,
  series_number,
  role,
  formType,
  form_version_id
) {
  // let annotations = processAnnotations(dicom_data);

  // console.log("savee annotations", {
  //   etdrsActive: dicom_data.etdrsActive,
  //   annotations: annotations,
  // });

  if (role === "adjudicator") {
    await axios
      .post(`${API_URL}/api/v1/qa-form-adjudicator`, {
        stack_id: stack_id,
        user_id: user_id,
        annotations: processAnnotations(dicom_data),
        adjudication_id: dicom_data.adjudication_id,
        form_type: formType,
        form_version_id: form_version_id,
        // status: "not_initiated",
      })
      .then((res) => {
        // setOpen(true);
        // setRespMessage({ error: false, message: "Data saved successfully" });
      })
      .catch((err) => {
        //setRespMessage({ error: true, message: "Unable to save data" });
      });
  } else {
    const resp = await axios.post(`${API_URL}/api/v1/file/save-annotations`, {
      stack_id: stack_id,
      annotations: processAnnotations(dicom_data),
      form_type: formType,
      form_version_id: form_version_id,
      mt: {
        stack_id,
        user_id,
        series_number,
        dicom_data_rest_annotation: dicom_data.dicom_data_rest_annotation ?? {},
      },
    });
  }
}
