import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-formio";
import axios from "axios";
import { API_URL } from "../../../Endpoints";
import { useDispatch, useSelector } from "react-redux";
import { processAnnotations } from "../actions/dcm_actions";
import { useLocation } from "react-router-dom";
import {
  deleteUnmatchedKeys,
  displayImageOverLay,
  extractFileName,
  findAllKeys,
  findETDRSMatches,
  findObjectByKey,
  getAllImages,
  updateETDRSValues,
} from "../utils/basic_utils";
import AlertDialog from "../../../Components/Dialog";

import { getLabelByValue } from "../utils/dicom_utils";
import PreviousData from "../../../Components/previousDataButton";
import useMutationObserver from "../../../customHooks.js/useMutaitonObserver";
import ImageShow from "../../../Components/ImageShow";

let sss_Urls = {};

let formDataReplica = {};
let UpdateForm = false;
let submitButtonClicked = false;

let GradersScreenshot_urls = {};

function extractKeys(data) {
  let keys = [];

  function traverse(item) {
    if (typeof item !== "object" || item === null) {
      return;
    }

    if (item.key) {
      keys.push(item.key);
    }

    // Recursively check all properties
    Object.values(item).forEach((value) => {
      if (Array.isArray(value)) {
        value.forEach(traverse);
      } else if (typeof value === "object" && value !== null) {
        traverse(value);
      }
    });
  }

  traverse(data);
  return keys;
}

const changeScreenShotKeys = (obj) => {
  const newObj = {};

  Object.keys(obj).forEach((mainKey) => {
    newObj[mainKey] = {};

    Object.keys(obj[mainKey]).forEach((key) => {
      newObj[mainKey][`${key} Files`] = obj[mainKey][key];
    });
  });

  return newObj;
};

function reorderUniqueLabels(uniqueLabels, keys) {
  // Create a Set from keys for faster lookup
  const keySet = new Set(keys);

  // Separate labels into two arrays: those in keys and those not in keys
  const inKeys = [];
  const notInKeys = [];

  uniqueLabels.forEach((label) => {
    if (keySet.has(label)) {
      inKeys.push(label);
    } else {
      notInKeys.push(label);
    }
  });

  // Sort the inKeys array based on the order in keys
  inKeys.sort((a, b) => keys.indexOf(a) - keys.indexOf(b));

  // Combine the sorted inKeys array with the notInKeys array
  return [...inKeys, ...notInKeys];
}
//
function GradingFormResponsesTable({
  labelsData,
  imageClicked,
  responses,
  labelsPair,
  query,
  adjudication_form_schema,
  GradingUrls,
}) {
  let responsessCopy = { ...responses };

  let keeyshere = Object.keys(responsessCopy);

  for (let i = 0; i < keeyshere.length; i++) {
    if (!responsessCopy[keeyshere[i]]?.responses.data) {
      delete responsessCopy[keeyshere[i]];
    }
  }

  const uuids = Object.keys(responsessCopy);
  if (uuids.length === 0) {
    return <p>Grading Data Not Found</p>;
  }

  const labels = uuids.reduce((acc, uuid) => {
    return [Object.keys(responsessCopy[uuid]?.responses.data)];
  }, []);

  let temp = [...new Set(labels)];
  temp[0].push("leasions_area_img");

  const uniqueLabels = temp[0];

  // Find the index of the string to remove
  let indexToRemove = uniqueLabels?.indexOf("submit");

  // If the string exists in the array, remove it
  if (indexToRemove !== -1) {
    uniqueLabels?.splice(indexToRemove, 1);
  }

  let totalAreaIndex = uniqueLabels?.indexOf("totalArea");

  // Remove "totalArea" from its original position
  let totalArea = uniqueLabels?.splice(totalAreaIndex, 1)[0];

  // Insert "totalArea" at the beginning of the array
  uniqueLabels?.unshift(totalArea);

  const keys = extractKeys(adjudication_form_schema);
  const reorderedUniqueLabels = reorderUniqueLabels(uniqueLabels, keys);

  console.log("DATA here", {
    uniqueLabels,
    uuids,
    labelsPair,
    responsessCopy,
    GradingUrls,
    adjudication_form_schema,
    keys: extractKeys(adjudication_form_schema),
  });

  // if (GradingUrls && typeof GradingUrls === "object") {
  //   if (Object.keys(GradingUrls[Object.keys(GradingUrls)[0]]).length > 0) {
  //     uniqueLabels.push(
  //       ...Object.keys(GradingUrls[Object.keys(GradingUrls)[0]])
  //     );
  //   }
  // }

  return (
    <table
      style={{
        borderCollapse: "collapse",
        width: "100%",
      }}
    >
      <thead>
        <tr>
          <th
            style={{
              border: "1px solid gray",
              padding: "8px",
              maxWidth: "150px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            Label
          </th>
          {uuids?.map((uuid) => (
            <th
              key={uuid}
              style={{
                border: "1px solid gray",
                padding: "8px",
                maxWidth: "150px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {}
              {responsessCopy[uuid]?.graded_by ?? uuid}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {reorderedUniqueLabels?.map((label, index) => (
          <React.Fragment key={index}>
            <tr>
              <td style={{ border: "1px solid gray", padding: "8px" }}>
                {labelsPair[label] ?? null}
              </td>
              {uuids?.map((uuid, index) => (
                <td
                  key={index}
                  style={{ border: "1px solid gray", padding: "8px" }}
                >
                  {findObjectByKey(adjudication_form_schema?.components, label)
                    ?.values?.length > 0 &&
                  findObjectByKey(adjudication_form_schema?.components, label)
                    ?.type === "radio"
                    ? getLabelByValue(
                        responsessCopy[uuid]?.responses?.data[label],
                        findObjectByKey(
                          adjudication_form_schema?.components,
                          label
                        )?.values
                      ) ?? responsessCopy[uuid]?.responses?.data[label]
                    : responsessCopy[uuid]?.responses?.data[label] ?? null}
                </td>
              ))}
            </tr>
            {GradingUrls &&
              GradingUrls[uuids[0]] &&
              Object.keys(GradingUrls[uuids[0]]).includes(label) &&
              label !== "lesions_area Files" && (
                <tr>
                  <td style={{ border: "1px solid gray", padding: "8px" }}></td>
                  {uuids?.map((uuid, index) => (
                    <td
                      key={index}
                      style={{ border: "1px solid gray", padding: "8px" }}
                    >
                      {GradingUrls[uuid][label] && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            overflowX: "auto",
                          }}
                        >
                          {GradingUrls[uuid][label]?.map((url, imgIndex) => (
                            <ImageShow key={imgIndex} imgUrl={url} />
                          ))}
                        </div>
                      )}
                    </td>
                  ))}
                </tr>
              )}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
}
export default function AdjQAForm(props) {
  // const [open, setOpen] = useState(false);
  const [gradingFormResponse, setGradingFormResponse] = useState({});

  const [UpdatedSchema, setupdatedSchema] = useState({});

  const [formData, setFormData] = useState({});
  const [labels, setLabels] = useState(null);
  const [respMessage, setRespMessage] = useState({ error: false, message: "" });
  const dicom_data = useSelector((state) => state.dcm);
  const dispatch = useDispatch();
  let { search } = useLocation();

  const formikRef = useRef(null);

  const query = new URLSearchParams(search);
  const rq = props.request_info;

  const imageClicked = (url) => {
    if (url !== undefined || dicom_data?.mainScreenShotUrl !== "") {
      dispatch({
        type: "displayScreenShot_url_changed",
        displayScreenShotUrl:
          typeof url === "string" || url instanceof String
            ? url
            : dicom_data?.mainScreenShotUrl,
      });
      dispatch({ type: "LoadingOverlay_changed", LoadingOverlay: true });
      dispatch({ type: "screenShot_Display_changed", screenShotDisplay: true });
    }
  };

  const updateContentForKey = (data, keyToFind, newContent, imageUrl) => {
    if (data?.adjudication_form_schema?.components) {
      for (let parentComponent of data.adjudication_form_schema.components) {
        if (parentComponent?.components) {
          for (let component of parentComponent.components) {
            if (component.key === keyToFind) {
              const updatedContent = newContent.replace(
                'src=""',
                `src="${imageUrl}"`
              );
              component.content = updatedContent;
              return; // Assuming only one match is needed, exit after updating
            }
          }
        }
      }
    }
  };

  const loadInitialDataAdjudicator = () => {
    console.log("loadInitialDataAdjudicator");
    axios
      .get(
        `${API_URL}/api/v1/qa-form-adjudicator?stack_id=${
          rq.stack_id
        }&user_id=${rq.user_id}${
          query.get("form_version_id")
            ? `&form_version_id=${query.get("form_version_id")}`
            : ""
        }`
      )
      .then((res) => {
        console.log("loadInitialDataAdjudicator then");
        UpdateForm = true;
        if (res.data) {
          const index =
            res?.data?.adjudication_form_schema?.components[0]?.components.findIndex(
              (component) => component.key === "leasions_area_img"
            );

          if (index !== -1) {
            // Store the removed component in state
            const [removed] =
              res?.data?.adjudication_form_schema?.components[0]?.components.splice(
                index,
                1
              );

            // Now components array is updated without the removed component
            // You can update the state or handle the new array as needed
          }
          if (res?.data) setFormData(res.data);
          if (res?.data?.grading_form_responses)
            setGradingFormResponse({ ...res?.data?.grading_form_responses });

          if (res.data?.grading_screenshot_urls) {
            sss_Urls = res.data?.grading_screenshot_urls;
          }
        }
        if (res?.data?.adjudication_id) {
          dispatch({
            type: "Adjudication_id_changed",
            adjudication_id: res?.data?.adjudication_id,
          });
        }

        if (res?.data?.adjudication_screenshot_urls?.lesions_area[0]) {
          if (
            res?.data?.adjudication_screenshot_urls?.lesions_area[0] &&
            dicom_data?.mainScreenShotUrl === null
          ) {
            if (dicom_data?.mainScreenShotUrl === null) {
              dispatch({
                type: "mainScreenShot_url_changed",
                mainScreenShotUrl:
                  res?.data?.adjudication_screenshot_urls?.lesions_area[0],
              });
            }
          }
        }

        console.log(
          "loadInitialDataAdjudicator removinggg check ",
          localStorage.getItem(query.get("stack_id"))
        );
        if (localStorage.getItem(query.get("stack_id"))) {
          console.log("removinggg");
          // localStorage.removeItem(query.get("stack_id"));
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    loadInitialDataAdjudicator();
  }, []);

  const handleExternalSubmit = () => {
    if (formikRef.current) {
      submitButtonClicked = true;
      formikRef.current.props.onSubmit(formikRef.current.props.submission);
    }
  };
  const updateETDRSValues = () => {
    let area_versions = dicom_data?.lisonsAreasData?.area_versions;
    const pixelatedObject = area_versions?.find(
      (obj) => obj.area_type === dicom_data?.lisonOptions?.lisonAreaselected
    );
    let values = {
      EZDA_ON_ETDRS_1MM: `${pixelatedObject?.lesions_1mm_area_mm2}  `,
      EZDA_ON_ETDRS_3MM: `${pixelatedObject?.lesions_3mm_area_mm2} `,
      EZDA_ON_ETDRS_6MM: `${pixelatedObject?.lesions_6mm_area_mm2} `,
      ETDRS_ON_OCT: `${pixelatedObject?.ETDRS_area_mm2} `,
    };

    const etdrsMatches = findETDRSMatches(
      formData?.adjudication_form_schema?.components,
      values
    );

    // Only include etdrsMatches if it's not empty and has valid values
    const hasValidMatches =
      Object.keys(etdrsMatches).length > 0 &&
      Object.values(etdrsMatches).every((value) => value !== "undefined ");

    return { etdrsMatches, hasValidMatches };
  };

  const handleButtonClick = () => {
    if (
      formData?.adjudication_form_schema?.components?.filter(
        (obj) =>
          (obj?.components &&
            obj?.components.some(
              (component) => component?.key === "totalArea"
            )) ||
          obj?.key === "totalArea"
      )
    ) {
      setFormData((prevState) => ({
        ...prevState,
        responses: {
          ...prevState.responses,
          data: {
            ...prevState.responses?.data,
            totalArea: dicom_data.SelectedArea.TotalArea, // Updating the value here
          },
        },
      }));
    }

    if (
      formData?.adjudication_form_schema?.components?.filter(
        (obj) =>
          (obj?.components &&
            obj?.components.some(
              (component) => component?.key === "lesions_area"
            )) ||
          obj?.key === "lesions_area"
      )
    ) {
      setFormData((prevState) => ({
        ...prevState,
        responses: {
          ...prevState.responses,
          data: {
            ...prevState.responses?.data,
            lesions_area: dicom_data.SelectedArea.LisonsArea,
          },
        },
      }));
    }
  };

  const updateETDRSValuesInForm = () => {
    const { etdrsMatches, hasValidMatches } = updateETDRSValues();

    // Check if values are different before updating
    const shouldUpdate =
      hasValidMatches &&
      Object.entries(etdrsMatches).some(
        ([key, value]) => formData?.responses?.data?.[key] !== value
      );

    if (shouldUpdate) {
      setFormData((prevState) => ({
        ...prevState,
        responses: {
          ...prevState.response,
          data: {
            ...prevState.responses?.data,
            ...etdrsMatches,
          },
        },
      }));
    }
  };
  useEffect(() => {
    if (dicom_data.etdrsActive) {
      updateETDRSValuesInForm();
    } else {
      // Clear ETDRS values when etdrsActive is false
      const emptyValues = {
        EZDA_ON_ETDRS_1MM: " ",
        EZDA_ON_ETDRS_3MM: " ",
        EZDA_ON_ETDRS_6MM: " ",
        ETDRS_ON_OCT: " ",
      };

      // First find which ETDRS fields exist in the form
      const etdrsMatches = findETDRSMatches(
        formData?.adjudication_form_schema?.components,
        emptyValues
      );

      // Only include fields that exist in the form
      const hasValidMatches = Object.keys(etdrsMatches).length > 0;

      // Check if values are different before updating
      const shouldUpdate =
        hasValidMatches &&
        Object.entries(etdrsMatches).some(
          ([key, value]) => formData?.response?.data?.[key] !== value
        );
      if (shouldUpdate) {
        setFormData((prevState) => ({
          ...prevState,
          responses: {
            ...prevState.response,
            data: {
              ...prevState.responses?.data,
              ...emptyValues,
            },
          },
        }));
      }
    }

    if (
      dicom_data.SelectedArea.TotalArea !==
        formData?.responses?.data?.totalArea &&
      dicom_data.SelectedArea.TotalArea !== null &&
      formData
    ) {
      handleButtonClick();
    }
  }, [
    dicom_data.SelectedArea,
    formData,
    dicom_data.mainScreenShotUrl,
    dicom_data?.lisonsAreasData,
    dicom_data.etdrsActive,
  ]);

  const submit_form = async (e) => {
    if (!submitButtonClicked) {
      return;
    }
    let dropZoneFiles = getAllImages(
      UpdatedSchema?.components
        ? UpdatedSchema.components
        : formData.adjudication_form_schema?.components
    );

    let filesNames = { ...dropZoneFiles };

    const allUrlsOriginal = Object.values(
      formData?.adjudication_screenshot_urls
    ).flat();

    const allUrls = Object.values(dropZoneFiles).flat();
    if (dicom_data?.mainScreenShotUrl)
      allUrls.push(dicom_data?.mainScreenShotUrl);

    const deletedImagesUrls = allUrlsOriginal.filter(
      (url) => !allUrls.includes(url)
    );

    Object.keys(filesNames).forEach((key) => {
      filesNames[key] = filesNames[key].map(extractFileName);
    });

    console.log("adj values", e.data);

    if (e.files) {
      e.files = {
        ...e.files,
        ...filesNames,
        ...(dicom_data?.mainScreenShotUrl && {
          lesions_area: [extractFileName(dicom_data.mainScreenShotUrl)],
        }),
      };
    } else {
      e["files"] = {
        ...filesNames,
        ...(dicom_data?.mainScreenShotUrl && {
          lesions_area: [extractFileName(dicom_data.mainScreenShotUrl)],
        }),
      };
    }

    const formSchemaKeys = findAllKeys(
      formData?.adjudication_form_schema?.components
    );

    deleteUnmatchedKeys(e?.data, formSchemaKeys);

    dispatch({ type: "LoadingOverlay_changed", LoadingOverlay: true });

    await axios
      .post(`${API_URL}/api/v1/qa-form-adjudicator`, {
        stack_id: rq.stack_id,
        user_id: rq.user_id,
        form_type: query.get("form_version_id")
          ? "additional_form"
          : "primary_form",
        form_version_id: query.get("form_version_id")
          ? query.get("form_version_id")
          : "",
        // screenshot_url: dicom_data?.mainScreenShotUrl,
        screenshot_urls: allUrls,
        deleted_screenshot_urls: deletedImagesUrls,
        annotations: processAnnotations(dicom_data),
        responses: e,
        status: "completed",
        id: formData.grading_form_id,
        adjudication_id: formData.adjudication_id,
      })
      .then((res) => {
        // setOpen(true);
        setRespMessage({ error: false, message: "Data saved successfully" });
        if (localStorage.getItem(query.get("stack_id"))) {
          localStorage.removeItem(query.get("stack_id"));
        }
      })
      .catch((err) => {
        setRespMessage({ error: true, message: "Unable to save data" });
      })
      .finally((event) => {
        submitButtonClicked = false;
        dispatch({ type: "LoadingOverlay_changed", LoadingOverlay: false });
      });
  };

  useMutationObserver(formData, setupdatedSchema, formDataReplica, UpdateForm);
  useEffect(() => {
    dispatch({
      type: "roledropdowndata_changed",
      formDataUserGrading: {
        data: formData.user_gradings,
      },
    });

    const labels_pair = {};
    if (formData) {
      formData.adjudication_form_schema?.components.forEach((element) => {
        element.components?.forEach((sub) => {
          labels_pair[sub.key] = sub.label;
        });
      });
      setLabels(labels_pair);
    }
  }, [formData]);

  if (!formData) {
    return "Form not found";
  }

  return (
    <div
      style={{
        backgroundColor: "#fff",
        padding: "0px 10px",
        overflow: "auto",
        height: "100%",
      }}
    >
      {formData?.previous_visit_data?.previous_record?.data &&
      dicom_data?.dicom_data[query.get("stack_id")]?.OPT?.url ? (
        <PreviousData formData={formData} />
      ) : null}

      {respMessage.message !== "" ? (
        <AlertDialog
          open={respMessage.message === "" ? false : true}
          handleClose={() => {
            setRespMessage({ error: false, message: "" });
          }}
          message={respMessage.message}
          error={respMessage.error}
        />
      ) : null}
      {Object.keys(gradingFormResponse)?.length > 0 && (
        <div>
          <h5>Previous Gradings</h5>
          <GradingFormResponsesTable
            labelsData={
              formData.adjudication_form_schema.components[0].components[0]
                .values
            }
            imageClicked={imageClicked}
            responses={gradingFormResponse}
            labelsPair={labels}
            query={query}
            // grading_screenshot_urls={formData?.grading_screenshot_urls}
            adjudication_form_schema={formData?.adjudication_form_schema}
            GradingUrls={formData?.grading_screenshot_urls}
          />
        </div>
      )}
      <h5 style={{ marginTop: "10px", marginBottom: "10px" }}>
        Adjudication Form...
      </h5>

      {formData.adjudication_form_schema && (
        <>
          <Form
            key={UpdatedSchema ? "updated" : "initial"}
            form={
              UpdatedSchema?.components
                ? UpdatedSchema
                : formData.adjudication_form_schema
            }
            submission={formData.responses}
            ref={formikRef}
            onSubmit={submit_form}
          />
          <div
            style={{
              flexDirection: "column",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "45px",
              height: "300px",
            }}
          >
            {dicom_data?.mainScreenShotUrl ? (
              <img
                style={{ cursor: "pointer" }}
                onClick={() => {
                  imageClicked(dicom_data?.mainScreenShotUrl);
                }}
                src={dicom_data?.mainScreenShotUrl}
                // src={grading_screenshot_urls[uuid]?.leasions_area[0]}
                alt="ScreenShot"
                height="200px"
                width="200px"
              />
            ) : null}
            <button
              onClick={handleExternalSubmit}
              // disabled={!dicom_data?.mainScreenShotUrl}
              disabled={dicom_data?.submitDisable}
              type="submit"
              style={{
                backgroundColor: dicom_data?.submitDisable ? "gray" : "#1565C0",
                color: "white",
                border: "0px",
                borderRadius: "5px",
                width: "250px",
                height: "50px",
                cursor: "pointer",
              }}
            >
              Submit Adjudication
            </button>
          </div>
        </>
      )}
    </div>
  );
}
