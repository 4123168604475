import cornerstoneTools from "cornerstone-tools";

const BaseAnnotationTool = cornerstoneTools.importInternal(
  "base/BaseAnnotationTool"
);

const external = cornerstoneTools.external;

const draw = cornerstoneTools.importInternal("drawing/draw");
const drawLine = cornerstoneTools.importInternal("drawing/drawLine");
const setShadow = cornerstoneTools.importInternal("drawing/setShadow");
const getNewContext = cornerstoneTools.importInternal("drawing/getNewContext");
const drawTextBox = cornerstoneTools.importInternal("drawing/drawTextBox");

const drawLinkedTextBox = cornerstoneTools.importInternal(
  "drawing/drawLinkedTextBox"
);
const getToolState = cornerstoneTools.getToolState;
//const toolStyle = cornerstoneTools.toolStyle
const getModule = cornerstoneTools.getModule;

const lineSegDistance = cornerstoneTools.importInternal("util/lineSegDistance");
const getPixelSpacing = cornerstoneTools.importInternal("util/getPixelSpacing");
const throttle = cornerstoneTools.importInternal("util/throttle");
const lengthCursor = cornerstoneTools.importInternal("cursors/index");
const debounce = cornerstoneTools.importInternal("util/debounce");

/**
 * @public
 * @class MultiLineTool
 * @memberof Tools.Annotation
 * @classdesc Tool for measuring distances.
 * @extends Tools.Base.BaseAnnotationTool
 */
const lisonsColors = [
  "rgba(255, 0, 0, 0.09)",
  "rgba(0, 128, 0, 0.09)",
  "rgba(255, 255, 0, 0.09)",
  "rgba(0, 0, 255, 0.09)",
  "rgba(128, 0, 128, 0.09)",
];
const textBoxAnchorPoints = {
  // Define the properties of textBoxAnchorPoints as needed
  // Example:
  start: { x: 0, y: 0 },
  end: { x: 0, y: 0 },
};
let count = 0;
export default class MultiLineTool extends BaseAnnotationTool {
  constructor(props = {}) {
    const defaultProps = {
      name: "MultiLine",
      supportedInteractionTypes: [],
      svgCursor: lengthCursor,
      configuration: {
        drawHandles: true,
        drawHandlesOnHover: false,
        hideHandlesIfMoving: false,
        renderDashed: false,
        digits: 2,
      },
    };

    super(props, defaultProps);

    this.throttledUpdateCachedStats = throttle(this.updateCachedStats, 110);
  }

  createNewMeasurement(eventData) {
    const goodEventData =
      eventData && eventData.currentPoints && eventData.currentPoints.image;

    if (!goodEventData) {
      return;
    }

    const { x, y } = eventData.currentPoints.image;

    return {
      visible: true,
      active: true,
      color: undefined,
      invalidated: true,
      handles: {
        start: {
          x,
          y,
          highlight: true,
          active: false,
        },
        end: {
          x,
          y,
          highlight: true,
          active: true,
        },
      },
    };
  }

  /**
   *
   *
   * @param {*} element
   * @param {*} data
   * @param {*} coords
   * @returns {Boolean}
   */
  pointNearTool(element, data, coords) {
    const hasStartAndEndHandles =
      data && data.handles && data.handles.start && data.handles.end;
    const validParameters = hasStartAndEndHandles;

    if (!validParameters) {
      return false;
    }

    if (data.visible === false) {
      return false;
    }

    return (
      lineSegDistance(element, data.handles.start, data.handles.end, coords) <
      25
    );
  }

  updateCachedStats(image, element, data) {
    const { rowPixelSpacing, colPixelSpacing } = getPixelSpacing(image);

    // Set rowPixelSpacing and columnPixelSpacing to 1 if they are undefined (or zero)
    const dx =
      (data.handles.end.x - data.handles.start.x) * (colPixelSpacing || 1);
    const dy =
      (data.handles.end.y - data.handles.start.y) * (rowPixelSpacing || 1);

    // Calculate the length, and create the text variable with the millimeters or pixels suffix
    const length = Math.sqrt(dx * dx + dy * dy);

    // Store the length inside the tool for outside access
    data.length = length;
    data.invalidated = false;
  }

  renderToolData(evt) {
    const eventData = evt.detail;
    const { renderDashed } = this.configuration;
    const toolData = getToolState(evt.currentTarget, this.name);

    if (!toolData) {
      return;
    }

    const context = getNewContext(eventData.canvasContext.canvas);
    const { image, element, canvasContext } = eventData;
    const lineDash = getModule("globalConfiguration").configuration.lineDash;

    // Calculate base coordinates (10px from right edge)
    const coords = {
      x: 35,
      y: image.height - toolData.data.length * 35, // 20px spacing between lines, 10px from bottom
    };

    for (let i = 0; i < toolData.data.length; ++i) {
      const data = toolData.data[i];
      console.log("daata", data);
      if (data.visible === false) {
        continue;
      }

      draw(context, (context) => {
        setShadow(context, this.configuration);
        for (let z = 0; z < data.data[0].handles.points.length; z++) {
          let pointsArray = data.data[0].handles.points[z];

          // Draw all lines first
          for (let j = 0; j < pointsArray.length; j++) {
            const lineOptions = {
              color: lisonsColors[z % lisonsColors.length],
            };
            if (renderDashed) {
              lineOptions.lineDash = lineDash;
            }

            let start = {
              x: pointsArray[j][0][0],
              y: pointsArray[j][0][1],
              highlight: true,
              active: false,
            };

            let end = {
              x: pointsArray[j][1][0],
              y: pointsArray[j][1][1],
              highlight: true,
              active: false,
            };

            drawLine(context, element, start, end, lineOptions);
          }
        }
        // Draw Labels Texts
        // Calculate dynamic spacing and sizing based on image dimensions
        const baseImageSize = 768; // Reference size for scaling
        const scaleFactor = Math.min(
          Math.sqrt(image.width * image.height) / baseImageSize,
          1.5
        );

        // Scale the spacing parameters with smaller values
        const padding = 5; // Fixed small padding from left
        // Adjust line height for better vertical spacing
        const lineHeight = Math.min(
          Math.max(image.height * 0.04, 25), // Increased to 4% of image height, minimum 25px
          45 // Increased maximum to 45px
        );
        const bottomMargin = lineHeight; // Set bottom margin equal to line height
        const fontSize = Math.min(
          Math.max(10 * scaleFactor, 8), // Base font size
          12 // Maximum 12px
        );

        // Set font size for context
        context.font = `${fontSize}px Arial`;

        // Base position for text boxes (bottom left)
        const baseX = padding;
        const baseY = image.height - bottomMargin;
        for (let i = 0; i < data.data[0].labelsData.length; i++) {
          let pointsArray = data.data[0].handles.points[i];
          const xOffset = 0;
          let labelData = data.data[0].labelsData[i];
          const text = labelData.label;

          // Draw label text box over each lesion
          console.log(
            "labelData.label_coordinates",
            labelData.label_coordinates
          );
          drawLinkedTextBox(
            context,
            element,
            labelData.label_coordinates,
            text,
            {
              end: {
                x: 621,
                y: 400,
              },
              start: {
                x: 621,
                y: 400,
              },
            },
            textBoxAnchorPoints,
            "white",
            5,
            xOffset,
            true
          );

          // Calculate position for area text
          const currentY = baseY - i * lineHeight;

          const textarea = `${labelData.label}: ${
            labelData.area
          } mm${String.fromCharCode(178)}`;

          console.log(
            "pointsArray point",
            pointsArray[pointsArray.length - 1][1]
          );
          //  // Draw area text box
          drawLinkedTextBox(
            context,
            element,
            {
              x: baseX,
              y: currentY,
            },
            textarea,
            {
              end: {
                x: 621,
                y: 400,
              },
              start: {
                x: 621,
                y: 400,
              },
            },
            textBoxAnchorPoints,
            "white",
            fontSize / 3,
            xOffset,
            true
          );

          // Draw total area if it's the last L and has total_area
          if (i === data.data[0].labelsData.length - 1) {
            console.log("print total area");
            const total_textarea = `TOT: ${
              data.data[0].total_area
            } mm${String.fromCharCode(178)}`;

            drawLinkedTextBox(
              context,
              element,
              {
                x: baseX,
                y: currentY - lineHeight,
              },
              total_textarea,
              {
                end: {
                  x: 0,
                  y: 0,
                },
                start: {
                  x: 0,
                  y: 0,
                },
              },
              textBoxAnchorPoints,
              "white",
              fontSize / 3,
              xOffset,
              true
            );
          }
        }
        // Draw text boxes
      });
    }
    const data = toolData.data[0];
    console.log("after draw text", data.data);
    // if (data?.data[0]?.areas) {
    //   console.log("dataaa for area", data);
    //   count++;
    //   let areas = data?.data[0]?.areas;

    //   // Set font size and font before measurements
    //   const fontSize = 12; // adjust this value as needed
    //   context.font = `${fontSize}px Arial`;

    //   // Add this helper function inside draw callback
    //   const measureText = (text) => {
    //     return context.measureText(text).width;
    //   };

    //   // Prepare all text strings first
    //   const textLines = [
    //     `⌀1 mm: ${areas.lesions_1mm_area_mm2} mm${String.fromCharCode(178)} (${
    //       areas.lesions_1mm_percentage
    //     }%)`,
    //     `⌀3 mm: ${areas.lesions_3mm_area_mm2} mm${String.fromCharCode(178)} (${
    //       areas.lesions_3mm_percentage
    //     }%)`,
    //     `⌀6 mm: ${areas.lesions_6mm_area_mm2} mm${String.fromCharCode(178)} (${
    //       areas.lesions_6mm_percentage
    //     }%)`,
    //     `ETDRS: ${areas?.ETDRS_area_mm2} mm${String.fromCharCode(178)} (${
    //       areas?.ETDRS_percentage
    //     }%)`,
    //   ];

    //   // Find the widest text and its pixel width
    //   const textWidths = textLines.map((text) => measureText(text));
    //   const maxWidth = Math.max(...textWidths);

    //   // Calculate text box height
    //   const lineHeight = 15;

    //   // const totalHeight = textLines.length * lineHeight;

    //   // Calculate dynamic padding based on image size
    //   const basePadding = 0;
    //   const baseImageSize = 768; // Reference size for scaling
    //   const scaleFactor = Math.min(image.width / baseImageSize, 2); // Cap the scale factor at 2

    //   const padding = Math.round(basePadding * scaleFactor);
    //   const extraRightPadding = Math.round(50 * scaleFactor); // Increased and scaled extra padding

    //   // Calculate the starting x-coordinate
    //   // Convert canvas coordinates to image coordinates for proper scaling
    //   const canvasToImageCoords = external.cornerstone.canvasToPixel(
    //     eventData.element,
    //     { x: maxWidth, y: 0 }
    //   );

    //   const scaledWidth = canvasToImageCoords.x;

    //   // Calculate coordinates ensuring text stays within image bounds
    //   const coords = {
    //     x: Math.max(
    //       padding,
    //       image.width - scaledWidth - padding * 1.3 - extraRightPadding
    //     ),
    //     y: image.height - padding,
    //   };

    //   // Convert image coordinates to canvas coordinates
    //   const textCoords = external.cornerstone.pixelToCanvas(
    //     eventData.element,
    //     coords
    //   );

    //   // Draw each line of text from bottom up
    //   textLines.forEach((text, index) => {
    //     drawTextBox(
    //       context,
    //       text,
    //       textCoords.x - 10,
    //       textCoords.y - (textLines.length - index) * lineHeight - 15,
    //       "white"
    //     );
    //   });
    // }
  }
}
