import React, { useState, useRef, useEffect, useCallback } from "react";
import cornerstone, { getLayers } from "cornerstone-core";
import cornerstoneTools from "cornerstone-tools";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  addStaticLineWithText,
  drawRectangleStatic,
  panImageEvent,
  ZoomMouseWheel,
  modifyOnPolylineClick,
  updateSelectedPointsLine,
  deleteSelectedPointsLine,
  addAnnotationsOPT,
  addAnnotationsOPTUpdate,
} from "./utils";
import { drawBrushPixels } from "../tools/util/drawBrush";
import { API_URL, ML_API_URL } from "../../../Endpoints";
import { lineColors } from "../configs/layers";
import drawJoinedLines from "../tools/annotation/drawing/drawJoinedLines";

import cornerstoneWADOImageLoader from "cornerstone-wado-image-loader";
import {
  get_normalized_x,
  get_slope_from_line,
  intercept_from_line,
} from "../utils/basic_utils";
import {
  createContours,
  simplifyContour,
  get_thickness_map,
} from "./processing/thickness";
import { useLocation } from "react-router-dom";

const getModule = cornerstoneTools.getModule;
const { getters, setters } = getModule("segmentation");

let toolUpdateva = false;
export default function OPTViewer(props) {
  const dispatch = useDispatch();
  let { search } = useLocation();

  const query = new URLSearchParams(search);
  const dicom_data = useSelector((state) => state.dcm);

  const opData = dicom_data.dicom_data[props.id];

  const [elmentEnabled, setElementEnabled] = useState(false);
  const dcmRef = useRef(null);
  const StackScrollMouseWheelTool = cornerstoneTools.StackScrollMouseWheelTool;
  const [rectPoint, setRectPoint] = useState([0, 0, 0, 0]);
  const [brushRadius, setBrushRadius] = useState(10);

  //const [loadCounter, setLoadCounter] = useState(0)
  const loadCounter = useRef(0);
  const getToolsState = () => {
    // Get tool state for all enabled tools

    const state = {};
    dicom_data.drawingTools.forEach((tool) => {
      const msms = cornerstoneTools.getToolState(dcmRef.current, tool);

      if (tool == "Length" && msms) {
        const pixel_spacing = opData.OP.metadata.pixel_spacing;
        msms.pixelSpacing = pixel_spacing;
        msms.data = msms.data.map((obj) => ({
          ...obj,
          pixelSpacing: {
            rowPixelSpacing: pixel_spacing.rowPixelSpacing,
            colPixelSpacing: pixel_spacing.columnPixelSpacing,
          },
        }));
      }
      state[tool] = msms;
    });
    let labelMap = getters.labelmap2D(dcmRef.current);

    state.Brush = { data: [labelMap.labelmap2D, labelMap.labelmap3D] };

    // dispatch({
    //   type: "ANNOTATION_CHANGE_REQUEST",
    //   stack_id: query.get("stack_id"),
    //   key: "OP",
    //   payload: {
    //     index: 0,
    //     annot_type: "LisonProbe",
    //     action_type: "Add_Lison_Length_point",
    //   },
    // });

    dispatch({
      type: "SET_ANNOTATION_DATA",
      payload: state,
      id: props.id,
      key: "OPT",
    });
    // dispatch({
    //   type: "ANNOTATION_CHANGE_REQUEST",
    //   stack_id: query.get("stack_id"),
    //   key: "OP",
    //   payload: {
    //     index: 0,
    //     annot_type: "Probe",
    //     action_type: "Add_Lison_Length_point",
    //   },
    // });
  };

  const incrementLoadCounter = () => {
    loadCounter.current += 1;
  };

  const get_mask = (points) => {
    axios
      .post(`${API_URL}/get-mask`, {
        data: points,
        image_id: props.id,
        index: opData.OPT.stack.currentImageIdIndex,
      })
      .then((res) => {
        if (points.length === 3 && points[2] === "bg") {
        } else {
          const {
            labelmap2D, // The `Labelmap2D` for this imageId.
            labelmap3D, // The `Labelmap3D` for this stack.
          } = getters.labelmap2D(dcmRef.current);

          // Get cornerstone image
          const image = cornerstone.getImage(dcmRef.current);
          const { columns } = image;

          const shouldErase = true;
          drawBrushPixels(
            res.data,
            labelmap2D.pixelData,
            labelmap3D.activeSegmentIndex,
            columns,
            false
          );
          cornerstone.updateImage(dcmRef.current);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (elmentEnabled) cornerstone.resize(dcmRef.current);
  }, [props.windowWidth, dicom_data.width]);

  const onMeasurementAdded = (e) => {
    // Get tool state for all enabled tools on measurementAdded

    getToolsState();
  };

  const debouncedDispatch = useCallback(
    debounce((e) => {
      if (e?.detail.toolName === "Lison") {
        dispatch({
          type: "ANNOTATION_CHANGE_REQUEST",
          stack_id: query.get("stack_id"),
          key: "OP",
          payload: {
            index: 0,
            annot_type: "LisonProbe",
            action_type: "Add_Lison_Length_point",
          },
        });
      }

      // Measurement modified

      getToolsState();
    }, 300),
    []
  ); // debounce delay set to 300ms

  const onMeasurementModified = (e) => {
    debouncedDispatch(e);
  };
  function debounce(func, delay) {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }
  useEffect(() => {
    // On delete click, delete selected points on line tool
    if (dicom_data.selectedKey === "Delete") {
      let data_line = cornerstoneTools.getToolState(dcmRef.current, "Line");
      if (data_line === undefined) {
        return;
      }
      deleteSelectedPointsLine(data_line);
      cornerstone.updateImage(dcmRef.current);
    }
  }, [dicom_data.selectedKey]);

  useEffect(() => {
    if (elmentEnabled)
      setters.activeSegmentIndex(
        dcmRef.current,
        dicom_data.settings.brush.activeSegment
      );
  }, [dicom_data.settings.brush.activeSegment]);

  const onMouseClick = (e) => {
    // On cornerstone Mouse chlick event
    if (dicom_data.selectedKey === "Alt") {
      // Modify line edges and vertices on alt+click
      let data_line = cornerstoneTools.getToolState(e.detail.element, "Line");
      if (data_line === undefined) return;

      data_line = data_line.data;

      modifyOnPolylineClick(data_line, e.detail.currentPoints.image);

      cornerstone.updateImage(e.detail.element);
    }
  };

  useEffect(() => {
    if (!elmentEnabled) {
      return;
    }
    opData.OPT.stack.currentImageIdIndex = dicom_data.currentFrame;
    dispatch({
      type: "SET_OPT_STACK",
      payload: opData.OPT.stack,
      id: props.id,
    });
  }, [dicom_data.currentFrame]);

  useEffect(() => {
    if (loadCounter.current % 7 === 0) {
      cornerstoneWADOImageLoader.webWorkerManager.terminate();
    }
  }, [loadCounter.current]);

  useEffect(() => {
    if (!elmentEnabled) {
      return;
    }

    // if (!dicom_data.syncScans) {
    //   if (dicom_data.changeId !== props.id) {
    //     return
    //   }
    // }

    if (dicom_data.changeId !== props.id) {
      return;
    }

    // On Stack image change
    // Only change, if request is from OP change or other OPT image is changed
    if (dicom_data.changeFrameType === "OP") {
      cornerstone
        .loadImage(
          opData.OPT.stack.imageIds[opData.OPT.stack.currentImageIdIndex]
        )
        .then((image) => {
          cornerstone.displayImage(dcmRef.current, image);
          incrementLoadCounter();
        });
    } else if (dicom_data.changeFrameType === "OPT") {
      // If it is OPT image, we load that specific frame at index
      if (dicom_data.changeId !== props.id) {
        cornerstone
          .loadImage(
            opData.OPT.stack.imageIds[opData.OPT.stack.currentImageIdIndex]
          )
          .then((image) => {
            cornerstone.displayImage(dcmRef.current, image);
            incrementLoadCounter();
          });
      }
    }

    // If show mask is enabled, we load the mask
    if (dicom_data.settings.brush.showMasks) {
      if (props.masks.length > opData.OPT.stack.currentImageIdIndex) {
        if (props.masks[opData.OPT.stack.currentImageIdIndex].length > 0) {
          const labelmap2D = getters.labelmap2D(dcmRef.current);

          labelmap2D.labelmap2D.pixelData =
            props.masks[opData.OPT.stack.currentImageIdIndex];
          cornerstone.updateImage(dcmRef.current);
        }
      }
    }

    getToolsState();
  }, [opData.OPT.stack.currentImageIdIndex]);
  //

  useEffect(() => {
    if (elmentEnabled) {
      // On vertical line points changed
      //  Draw vertical line with specified points
      cornerstoneTools.clearToolState(dcmRef.current, "LineStatic");
      if (opData.OP.vertical_line !== null) {
        addStaticLineWithText(dcmRef, opData.OPT.vertical_line, ``);
      }
      cornerstone.updateImage(dcmRef.current);
    }
  }, [opData.OPT.vertical_line]);

  const addRectangleStatic = () => {
    const RectangleStatic_v = cornerstoneTools.RectangleStatic;
    cornerstoneTools.addTool(RectangleStatic_v);
    cornerstoneTools.setToolActive("RectangleStatic", { mouseButtonMask: 1 });
  };

  const addLineStatic = () => {
    const LineStatic_v = cornerstoneTools.LineStatic;
    cornerstoneTools.addTool(LineStatic_v);
    cornerstoneTools.setToolActive("LineStatic", { mouseButtonMask: 1 });
  };

  const onMouseDown = (e) => {
    if (dicom_data.selectedTool === "Lison") {
      let tool_name = dicom_data.selectedTool;
      const tool_v = cornerstoneTools[tool_name + "Tool"];

      cornerstoneTools.addTool(tool_v);
      cornerstoneTools.setToolActive(tool_name, {
        mouseButtonMask: 1,
      });
    }

    // Record initial position of static rectangle is shift is pressed
    if (
      ["MOUSE", "Brush"].includes(dicom_data.selectedTool) &&
      ["Shift", "a", "A"].includes(dicom_data.selectedKey)
    ) {
      setRectPoint([
        e.detail.currentPoints.image.x,
        e.detail.currentPoints.image.y,
        0,
        0,
      ]);
    }
  };

  const onMouseUp = (e) => {
    dispatch({
      type: "Lison_Input_Submit_Status_changed",
      MlLisonsInputsSubmitStatus: true,
    });
    // if (dicom_data.selectedTool === "Lison") {
    //   dispatch({
    //     type: "ANNOTATION_CHANGE_REQUEST",
    //     stack_id: query.get("stack_id"),
    //     key: "OP",
    //     payload: {
    //       index: 0,
    //       annot_type: "LisonProbe",
    //       action_type: "Add_Lison_Length_point",
    //     },
    //   });
    // }
    // On mouse up, if shift is pressed, we will draw a rectangle
    if (
      dicom_data.selectedTool === "MOUSE" &&
      dicom_data.selectedKey === "Shift"
    ) {
      // also update colors for all points inside that rectangle for each line
      updateSelectedPointsLine(
        cornerstoneTools.getToolState(e.detail.element, "Line"),
        [
          rectPoint[0],
          rectPoint[1],
          e.detail.currentPoints.image.x,
          e.detail.currentPoints.image.y,
        ]
      );

      cornerstoneTools.clearToolState(e.detail.element, "RectangleStatic");
      cornerstone.updateImage(e.detail.element);
    } else if (
      dicom_data.selectedTool === "Brush" &&
      dicom_data.selectedKey === "a"
    ) {
      // also update colors for all points inside that rectangle for each line
      get_mask([
        rectPoint[0],
        rectPoint[1],
        e.detail.currentPoints.image.x,
        e.detail.currentPoints.image.y,
      ]);

      updateSelectedPointsLine(
        cornerstoneTools.getToolState(e.detail.element, "Line"),
        [
          rectPoint[0],
          rectPoint[1],
          e.detail.currentPoints.image.x,
          e.detail.currentPoints.image.y,
        ]
      );
      cornerstoneTools.clearToolState(e.detail.element, "RectangleStatic");
      cornerstone.updateImage(e.detail.element);
    }
  };
  useEffect(() => {
    if (dicom_data?.OpRef?.current) {
      console.log("AOA Op_Ref_Changed AOA");
      dispatch({
        type: "dicomRefBscan_changed",
        dicomRef: dcmRef,
      });
    }
  }, [dicom_data.OpRef]);
  const onMouseDrag = (e) => {
    // On mouse drag, if control is pressed, we will pan the image
    if (dicom_data.selectedKey === "Shift") {
      panImageEvent(e);
      return;
    }

    // If we are not in a tool, we will not do anything
    // For this, we draw a temporary rectangle
    if (
      ["MOUSE", "Brush"].includes(dicom_data.selectedTool) &&
      ["Shift", "a", "A"].includes(dicom_data.selectedKey)
    ) {
      drawRectangleStatic(
        dcmRef,
        [
          rectPoint[0],
          rectPoint[1],
          e.detail.currentPoints.image.x,
          e.detail.currentPoints.image.y,
        ],
        0
      );

      cornerstone.updateImage(dcmRef.current);
      return;
    }

    // If selected tool is not drag tool, we will not do anything
    if (dicom_data.selectedTool !== "DragRL") {
      return;
    }

    // Drag Left to right or right to left OCT
    let currentFrameData = opData.OPT.metadata.frames[dicom_data.currentFrame];
    let normalizedX = get_normalized_x(
      e,
      currentFrameData,
      opData.OPT.metadata.meta.width
    );
    if (normalizedX === null) {
      return;
    }

    let slope = get_slope_from_line(currentFrameData);
    let intercept = intercept_from_line(currentFrameData);

    let yh = slope * normalizedX + intercept;

    dispatch({
      type: "DICOM_CHANGE_VERTICAL_LINE",
      OP: [normalizedX, yh - 50, normalizedX, yh + 50],
      OPT: [
        e.detail.currentPoints.image.x,
        0,
        e.detail.currentPoints.image.x,
        opData.OPT.metadata.meta.height,
      ],
      id: props.id,
    });
  };

  const onMouseMove = (e) => {};

  const onMouseWheel = (e) => {
    // Zoom on mosue wheel with ctrl key
    if (dicom_data.selectedKey === "Shift") {
      ZoomMouseWheel(e, cornerstone);
    } else if (
      ["z", "Z"].includes(dicom_data.selectedKey) &&
      dicom_data.selectedTool === "Brush"
    ) {
      //setters.radius(value);
      // If mouse is on image, we perform custom stack scroll
      if (e.detail.spinY > 0) {
        if (brushRadius > 1) {
          setBrushRadius(brushRadius - 1);
        }
      } else {
        if (brushRadius < 100) {
          setBrushRadius(brushRadius + 1);
        }
      }
      return;
    }
  };

  useEffect(() => {
    if (dicom_data.selectedTool === "Brush") {
      setters.radius(brushRadius);
      cornerstone.updateImage(dcmRef.current);
    }
  }, [brushRadius]);

  useEffect(() => {
    if (elmentEnabled) cornerstone.updateImage(dcmRef.current);
  }, [dicom_data.update]);

  useEffect(() => {
    if (elmentEnabled) {
      // If toggle annotation clicked for a line tool or delete annotation clicked
      let update_d = opData.OPT.annotation_toggle;

      if (update_d === undefined) {
        return;
      }
      const toolState = cornerstoneTools.getToolState(
        dcmRef.current,
        update_d.annot_type
      );
      if (toolState === null || undefined) {
        return;
      }

      if (update_d.action_type === "visibility") {
        var imgIds = opData.OPT.stack.imageIds;

        const frames = [];
        for (var i = 0; i < imgIds.length; i++) {
          // Get frame annotations for all tools
          var toolAnnotations =
            cornerstoneTools.globalImageIdSpecificToolStateManager.getImageIdToolState(
              imgIds[i],
              "Line"
            );

          var j = update_d.index;
          if (!toolAnnotations.data[j].visible) {
            toolAnnotations.data[j].visible = true;
          } else {
            toolAnnotations.data[j].visible = false;
          }
          frames.push(toolAnnotations);
        }

        getToolsState();
      } else if (update_d.action_type === "visibility_all") {
        toolState.visible = !toolState.visible;
        for (let i = 0; i < toolState.data.length; i++) {
          toolState.data[i].visible = toolState.visible;
        }
        // Update tool state

        getToolsState();
      } else if (update_d.action_type === "toggle_handles") {
        toolState.handles = !toolState.handles;

        getToolsState();
      } else if (update_d.action_type === "delete") {
        toolState.data.splice(update_d.index, 1);

        getToolsState();
        if (update_d.annot_type === "Lison") {
          dispatch({
            type: "ANNOTATION_CHANGE_REQUEST",
            stack_id: query.get("stack_id"),
            key: "OP",
            payload: {
              index: 0,
              annot_type: "LisonProbe",
              action_type: "Add_Lison_Length_point",
            },
          });
        }
      } else if (update_d.action_type === "toggle_Lison") {
        let imgIds = opData.OPT.stack.imageIds;
        let previous_values = [];
        for (var i = 0; i < imgIds.length; i++) {
          var toolAnnotations =
            cornerstoneTools.globalImageIdSpecificToolStateManager.getImageIdToolState(
              imgIds[i],
              "Lison"
            );

          if (toolAnnotations?.data) {
            if (!dicom_data.LisonActive) {
              let obj = {};
              obj[imgIds[i]] = toolAnnotations.data;
              previous_values = [...previous_values, obj];
              toolAnnotations.data = [];
            }
            if (dicom_data.LisonActive) {
              // let LisonData_dicom = dicom_data.LisonData;
              let foundObject = dicom_data.LisonData.find((obj) =>
                obj.hasOwnProperty(imgIds[i])
              );
              if (foundObject) {
                toolAnnotations.data = foundObject[imgIds[i]];
              }
            }
          }
        }
        if (!dicom_data.LisonActive) {
          dispatch({
            type: "Lison_toggle_DATA",
            LisonData: previous_values,
          });
        }

        getToolsState();

        dispatch({
          type: "ANNOTATION_CHANGE_REQUEST",
          stack_id: query.get("stack_id"),
          key: "OP",
          payload: {
            index: 0,
            annot_type: "LisonProbe",
            action_type: "Add_Lison_Length_point",
          },
        });
      }
      cornerstone.updateImage(dcmRef.current);
    }
  }, [opData.OPT.annotation_toggle, dicom_data.LisonActive]);

  const metaDataProvider = (type, imageId) => {
    // Custom meta data provider for the viewport(OCT)
    if (type === "imagePlaneModule") {
      if (
        imageId.includes(opData.OPT.metadata.url) &&
        dicom_data.selectedTool !== "Brush"
      ) {
        return {
          rows: opData.OPT.metadata.meta.height,
          columns: opData.OPT.metadata.meta.width,
          rowPixelSpacing: null, //opData.OPT.metadata.pixel_spacing.rowPixelSpacing,
          columnPixelSpacing: null, //opData.OPT.metadata.pixel_spacing.columnPixelSpacing,
        };
      }
    }
  };

  useEffect(() => {
    if (opData.OPT.metadata.pixel_spacing.rowPixelSpacing !== null) {
      cornerstone.metaData.addProvider(metaDataProvider);
    }
  }, [Object.keys(dicom_data.dicom_data).length, dicom_data.selectedTool]);

  useEffect(() => {
    const update_data = dicom_data.update_data;
    if (update_data.data) {
      var lineObj = {};
      for (var i = 0; i < update_data.data.length; i++) {
        lineObj[update_data.data[i].name] = update_data.data[i];
      }

      var toolStateOld = cornerstoneTools.getToolState(dcmRef.current, "Line");
      var toolStateOldN = {};
      if (toolStateOld.data) {
        var toolStateOld_data = [...toolStateOld.data];
        for (var i = 0; i < toolStateOld_data.length; i++) {
          toolStateOldN[toolStateOld_data[i].name] = toolStateOld_data[i];
        }
      }

      cornerstoneTools.clearToolState(dcmRef.current, "Line");

      var { lineNames } = dicom_data.lineSettings;
      for (var j = 0; j < lineNames.length; j++) {
        var lName = lineNames[j];
        if (lName in toolStateOldN) {
          // If Line is already in old annotations
          if (toolStateOldN[lName].visible && lName in lineObj) {
            // If it is visible and is in models output
            cornerstoneTools.addToolState(
              dcmRef.current,
              "Line",
              lineObj[lName]
            );
          } else {
            // Using old layers
            cornerstoneTools.addToolState(
              dcmRef.current,
              "Line",
              toolStateOldN[lName]
            );
          }
        } else if (lName in lineObj) {
          // Line is not available in old data
          cornerstoneTools.addToolState(dcmRef.current, "Line", lineObj[lName]);
        }
      }

      cornerstone.updateImage(dcmRef.current);
    }
  }, [dicom_data.update_data]);

  useEffect(() => {
    if (!elmentEnabled) {
      return;
    }
    const modifiedObject = {};

    for (const key in props.annotations) {
      if (props.annotations.hasOwnProperty(key)) {
        const lastPart = key.split("?frame=")[1]; // Extract the last part after "?frame="
        modifiedObject["frame=" + lastPart] = props.annotations[key];
      }
    }
    addAnnotationsOPT(
      opData.OPT.stack.imageIds,
      modifiedObject,
      dicom_data.LisonActive
    );
  }, [dicom_data.LisonActive]);
  useEffect(() => {
    let element = dcmRef.current;

    cornerstone
      .loadImage(
        opData.OPT.stack.imageIds[opData.OPT.stack.currentImageIdIndex]
      )
      .then((image) => {
        // Enable the DOM Element for use with Cornerstone

        cornerstone.enable(element);
        cornerstone.displayImage(element, image);
        setElementEnabled(true);

        // set the stack as tool state
        cornerstoneTools.addStackStateManager(element, ["stack", "Crosshairs"]);
        cornerstoneTools.addToolState(element, "stack", opData.OPT.stack);
        cornerstoneTools.addTool(StackScrollMouseWheelTool);
        cornerstoneTools.setToolActive("StackScrollMouseWheel", {});

        element.addEventListener(
          "cornerstonetoolsmeasurementadded",
          onMeasurementAdded
        );
        element.addEventListener(
          "cornerstonetoolsmeasurementmodified",
          onMeasurementModified
        );
        //element.addEventListener("cornerstoneelementresized", onWindowResize);
        addRectangleStatic();
        addLineStatic();
        //addCircleStatic()
        //cornerstoneTools.addTool(brushTool);
        //cornerstoneTools.setToolActive('Brush', { mouseButtonMask: 1 });

        const modifiedObject = {};

        for (const key in props.annotations) {
          if (props.annotations.hasOwnProperty(key)) {
            const lastPart = key.split("?frame=")[1]; // Extract the last part after "?frame="
            modifiedObject["frame=" + lastPart] = props.annotations[key];
          }
        }
        addAnnotationsOPT(
          opData.OPT.stack.imageIds,
          modifiedObject,
          dicom_data.LisonActive
        );

        // ON New Cornerstone Image (Mouse Scroll)
        element.addEventListener("cornerstonenewimage", function (e) {
          incrementLoadCounter();
          dispatch({
            type: "DICOM_CHANGE_FRAME",
            changeType: "OPT",
            changeId: props.id,
            frame: cornerstoneTools.getToolState(element, "stack").data[0]
              .currentImageIdIndex,
          });

          getToolsState();
        });

        // Add WWWC Tool
        cornerstoneTools.addTool(cornerstoneTools.WwwcTool);
        cornerstoneTools.setToolActive("Wwwc", { mouseButtonMask: 1 });

        // if (props.masks[0].length > 0) {
        //   const labelmap2D = getters.labelmap2D(dcmRef.current);
        //   labelmap2D.labelmap2D.pixelData = props.masks[0];
        // }
        // setters.labelmap3DForElement(element, [props.masks], 1)

        getToolsState();
      })
      .then(() => {
        cornerstone.updateImage(element);
      });
  }, [Object.keys(dicom_data.dicom_data).length]);

  useEffect(() => {
    let element = dcmRef.current;
    element.addEventListener("cornerstonetoolsmousedown", onMouseDown);
    element.addEventListener("cornerstonetoolsmousemove", onMouseMove);
    element.addEventListener("cornerstonetoolsmouseup", onMouseUp);
    element.addEventListener("cornerstonetoolsmousedrag", onMouseDrag);
    element.addEventListener("cornerstonetoolsmouseclick", onMouseClick);
    element.addEventListener("cornerstonetoolsmousewheel", onMouseWheel);
    return () => {
      element.removeEventListener("cornerstonetoolsmousedown", onMouseDown);
      element.removeEventListener("cornerstonetoolsmouseup", onMouseUp);
      element.removeEventListener("cornerstonetoolsmousemove", onMouseMove);
      element.removeEventListener("cornerstonetoolsmousedrag", onMouseDrag);
      element.removeEventListener("cornerstonetoolsmouseclick", onMouseClick);
      element.removeEventListener("cornerstonetoolsmousewheel", onMouseWheel);
    };
  }, [
    dicom_data.currentFrame,
    dicom_data.selectedTool,
    dicom_data.selectedKey,
    rectPoint,
    brushRadius,
  ]);

  return (
    <div
      style={{
        height: props.height,
        width: props.width,
        backgroundColor: "#000",
      }}
      ref={dcmRef}
    ></div>
  );
}
