import React from "react";
import { displayImageOverLay } from "../Views/DCMViewer/utils/basic_utils";
import { useDispatch } from "react-redux";

const ImageShow = ({ imgUrl }) => {
  const dispatch = useDispatch();
  return (
    <img
      onClick={() => {
        // imageClicked(sss_Urls[uuid]?.lesions_area[0]);
        displayImageOverLay(imgUrl, dispatch);
      }}
      src={imgUrl}
      style={{ cursor: "pointer" }}
      // src={grading_screenshot_urls[uuid]?.leasions_area[0]}
      alt="ScreenShot"
      height="200px"
      width="200px"
    />
  );
};

export default ImageShow;
